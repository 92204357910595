import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
} from "@mui/material/";
import TimezoneSelect from "react-timezone-select";
import { useSelector, useDispatch } from "react-redux";
import { postTimezone, setUserTimezone } from "../../../redux/modules/auth";

export default function TimezoneSettings() {
  const [loading, setLoading] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState({ value: "" });
  const currentTZ = useSelector((state) => state.auth.timezone);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTimezone.value != currentTZ) {
      setSelectedTimezone({ value: currentTZ });
    }
  }, [currentTZ]);

  const onTimezoneSelect = (tz) => {
    dispatch(setUserTimezone(tz.value));
    setLoading(true);
    dispatch(postTimezone(tz.value))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Card className="list_paper" style={{ overflow: "unset" }}>
        <List className="settings_list">
          <ListItem className="list_item">
            <ListItemText
              primary={<Typography>Your Timezone</Typography>}
              secondary={
                <Typography variant="caption">
                  You can change your timezone here
                </Typography>
              }
            />
            <div>
              <TimezoneSelect
                value={selectedTimezone}
                onChange={onTimezoneSelect}
              />
            </div>
          </ListItem>
        </List>
        {loading ? <LinearProgress /> : null}
      </Card>
    </>
  );
}
