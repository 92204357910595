import {
  IconButton,
  Typography,
  Button,
  Chip,
  Collapse,
  LinearProgress,
  Tooltip,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDealNotes,
  postDNoteAndGetNotes,
} from "../../../redux/modules/deals";
import format_date from "../../../utils/format_date";
import {
  AccountCircle as AccountCircleIcon,
  Add as AddIcon,
  Close as CloseIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  AttachFile as AttachFileIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from "@mui/icons-material";
import config from "../../../config";

function NoteText({ item }) {
  const [readM, setReadM] = useState(false);
  const noteContent = readM ? item.deal_note : item.deal_note.slice(0, 240);
  const isLong = item.deal_note.length > 240;
  const readMore =
    isLong && !readM ? (
      <>
        ...{" "}
        <span className="read-more-btn" onClick={() => setReadM(true)}>
          Read more
        </span>
      </>
    ) : isLong ? (
      <span className="read-more-btn" onClick={() => setReadM(false)}>
        {" "}
        Read less
      </span>
    ) : null;

  return (
    <Typography className="note-text">
      {noteContent}
      {readMore}
    </Typography>
  );
}

function DealNotes({ dealId }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const dealNotes = useSelector((state) => state.deals.dealNotes);
  const dnCount = useSelector((state) => state.deals.noteCount);
  const [note, setNote] = useState("");
  const [addN, setAddN] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getDealNotes(dealId, config.dNotes.limit, page - 1)).then(() => {
      setIsLoading(false);
    });
  }, [page]);

  const onAddIconClick = () => {
    setAddN(!addN);
  };

  const onNoteChange = (e) => {
    setNote(e.target.value);
  };

  const onSaveDealNote = () => {
    setIsLoading(true);
    dispatch(
      postDNoteAndGetNotes(dealId, note, config.dNotes.limit, page - 1, files)
    ).then(() => {
      setIsLoading(false);
      setAddN(false);
      setNote("");
      setFiles([]);

      if (page != 1) {
        setPage(1);
      }
    });
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const prePage = () => {
    setPage(page - 1);
  };

  const onFileUpload = (e) => {
    if (e.target.files.length) {
      setFiles([...files, e.target.files[0]]);
    }
  };

  const handleFileDelete = (f) => () => {
    const newFiles = files;

    setFiles([
      ...newFiles.slice(0, f),
      ...newFiles.slice(f + 1, newFiles.length),
    ]);
  };

  const onFileChipClick = (file) => () => {
    const link = document.createElement("a");
    link.href = file.link;
    link.setAttribute("download", file.name);
    link.setAttribute("target", "__blank");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <div>
        <div className="add-note-btn-wrapper">
          <Tooltip title={addN ? "Close" : "Add Note"}>
            <IconButton onClick={onAddIconClick}>
              {addN ? <CloseIcon /> : <AddIcon />}
            </IconButton>
          </Tooltip>
        </div>
        <Collapse in={addN}>
          <textarea
            className="add-note"
            placeholder="Enter note"
            value={note}
            onChange={onNoteChange}
            disabled={isLoading}
          ></textarea>
          <div>
            {files.map((file, f) => (
              <Chip
                key={f}
                className="file-attached"
                variant="outlined"
                size="small"
                onDelete={handleFileDelete(f)}
                icon={<InsertDriveFileIcon />}
                label={file.name}
              />
            ))}
          </div>
          <div className="add-note-button">
            <Button
              autoFocus
              onClick={onSaveDealNote}
              variant="contained"
              color="primary"
              disabled={!note || isLoading}
            >
              Add Note
            </Button>
            <input
              style={{ display: "none" }}
              id="file-upload-button"
              type="file"
              onChange={onFileUpload}
            />
            <label htmlFor="file-upload-button">
              <IconButton color="primary" component="span">
                <AttachFileIcon />
              </IconButton>
            </label>
          </div>
        </Collapse>
      </div>
      {isLoading ? <LinearProgress /> : null}
      <div className="notes-list-container">
        {dealNotes.length ? (
          dealNotes.map((item, i) => (
            <div className="note-item deal-last-note" key={i}>
              <div className="leadcardbg deal-note">
                <NoteText key={item.created_time} item={item} />
              </div>
              <div>
                {item.attachments &&
                  item.attachments.map((file, f) => (
                    <Chip
                      key={f}
                      className="file-attached"
                      variant="outlined"
                      size="small"
                      icon={<InsertDriveFileIcon />}
                      label={file.name}
                      onClick={onFileChipClick(file)}
                      clickable
                    />
                  ))}
              </div>
              <div className="note-bottom-panel">
                <Typography variant="caption">
                  {format_date(item.created_time)}
                </Typography>
                <div className="notes-owner-sec">
                  <AccountCircleIcon
                    color="primary"
                    className="notes-owner-img"
                  />
                  <Typography variant="caption">{item.user}</Typography>
                </div>
              </div>
            </div>
          ))
        ) : !isLoading ? (
          <Typography className="no-info">No notes yet.</Typography>
        ) : null}
      </div>
      {dealNotes.length ? (
        <>
          <Divider />
          <div className="dn-nav-cont">
            <Typography variant="caption">
              {page}/{Math.ceil(dnCount / 3)}
            </Typography>
            <div className="dn-nav-wrapper">
              <IconButton onClick={prePage} disabled={page === 1}>
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                onClick={nextPage}
                disabled={!(dnCount - page * config.dNotes.limit > 0)}
              >
                <NavigateNextIcon />
              </IconButton>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default DealNotes;
