import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Tabs,
  Tab,
  LinearProgress,
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { getAForm } from "../../../redux/modules/forms";
import config from "../../../config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}


function FormIntegration({ formId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const formData = useSelector((state) => state.forms.formData);
  const embedCode = useSelector((state) => state.forms.embedCode);
  const notchValue = useSelector((state) => state.forms.notchValue);
  const [value, setValue] = React.useState(0);

  const handleSnippetOpen = () => {
    setOpen(true);
    if (formId) {
      setLoading(true);
      dispatch(getAForm(formId))
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setCopied(false);
  };

  const handleCopy = () => {
    if (value == 1) {
      navigator.clipboard.writeText(embedCode);
    } else if (value == 2) {
      navigator.clipboard.writeText(`${config.formsLinkUrl}${notchValue}`);
    } else {
      navigator.clipboard.writeText(formData);
    }
    setCopied(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (copied) {
      setCopied(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        className="bdr-btn-style-blue"
        onClick={handleSnippetOpen}
      >
        Get
      </Button>
      {/* Dialog component to display the code snippet of a form */}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle onClose={handleClose}>Deploy Form</DialogTitle>
        <Divider />
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            className="form-tabs"
          >
            <Tab className="form-tab" label="Snippet (deprecated)" />
            <Tab className="form-tab" label="Embed code" />
            <Tab className="form-tab" label="Share link" />
          </Tabs>
        </div>
        <DialogContent>
          <TabPanel value={value} index={0}>
            <Typography>
              To embed this form, simply copy and paste the code below into the
              HTML code on your website. This is a deprecated method.
            </Typography>
            {!loading ? (
              <pre className="code-formater">
                <div className="code-snippet">{formData}</div>
              </pre>
            ) : (
              <LinearProgress />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography>
              To embed this form, simply copy and paste the code below into the
              HTML code on your website.
            </Typography>
            <pre className="code-formater">
              <div className="code-snippet">{embedCode}</div>
            </pre>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography>
              You can share this form with the link below or add the this link
              to your website.
            </Typography>
            <pre className="code-formater">
              <div className="code-snippet">
                {`${config.formsLinkUrl}${notchValue}`}
              </div>
            </pre>
          </TabPanel>
        </DialogContent>
        <Divider />
        <DialogActions>
          {copied ? (
            <Typography>Copied</Typography>
          ) : !navigator.clipboard.writeText ? (
            <Typography>Copy manually</Typography>
          ) : null}
          <Button
            autoFocus
            style={{ marginLeft: 8 }}
            className="btn-style"
            variant="contained"
            onClick={handleCopy}
            disabled={!navigator.clipboard.writeText}
          >
            Copy
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FormIntegration;
