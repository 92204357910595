import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  SnackbarContent,
  Snackbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { markFeaturesAsRead } from "../../redux/modules/noauth";
import { Close } from "@mui/icons-material";
import { truncateString } from "../../utils";

function NotiPop() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [pop, setPop] = useState(false);
  const changeLog = useSelector((state) => state.auth.change_log);
  const newRelease = changeLog[0];

  const handleClose = () => {
    setOpen(false);
    dispatch(markFeaturesAsRead(newRelease.tb_id));
  };

  const onPopClose = () => {
    setPop(false);
  };

  const onShowClick = () => {
    setPop(true);
    setOpen(false);
    dispatch(markFeaturesAsRead(newRelease.tb_id));
  };

  const onLinkClick = () => {
    setPop(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open && newRelease}
        key="bottom-right"
      >
        <SnackbarContent
          message={`New features available in V${
            newRelease && newRelease.version
          }`}
          action={
            <>
              <Button color="primary" onClick={onShowClick}>
                Show
              </Button>
              <IconButton color="primary" onClick={handleClose}>
                <Close />{" "}
              </IconButton>
            </>
          }
        />
      </Snackbar>
      <Dialog open={pop} onClose={onPopClose}>
        <DialogTitle>
          <Typography variant="h1">
            {newRelease && newRelease.release_name}
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: newRelease && newRelease.release_details,
            }}
          ></div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Link className="link_style" to="/change-log" onClick={onLinkClick}>
            View all releases
          </Link>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NotiPop;
