import { format, formatDistance } from "date-fns";
import store from "../redux/store";
import { formatInTimeZone, zonedTimeToUtc } from "date-fns-tz";
import config from "../config";

const dateTimeFormats = config.dateTimeFormats;
const getTimezone = () => store.getState().auth.timezone;
const getDTFormat = () =>
  (
    dateTimeFormats.find((dtf) => dtf.timezone === getTimezone()) ||
    dateTimeFormats.find((dtf) => dtf.timezone === "")
  ).format;

export default function format_date(date) {
  if (date) {
    const utcDate = `${date.replace(" ", "T")}Z`;
    return `${formatInTimeZone(new Date(utcDate), getTimezone(), getDTFormat())}
        ${formatDistance(new Date(utcDate), new Date(), {
          addSuffix: true,
          includeSeconds: true,
        })} `;
  }

  return date;
}

export function clearTnZ(value) {
  return value.replace("T", " ").slice(0, -5);
}

export function getDateTimeWith12(dateTime) {
  const utcDateTime = new Date(`${dateTime.replace(" ", "T")}Z`);

  return `${formatInTimeZone(
    utcDateTime,
    getTimezone(),
    getDTFormat()
  )} ${formatDistance(utcDateTime, new Date(), {
    addSuffix: true,
    includeSeconds: true,
  })}`;
}

export function getDateTimeWith12R(dateTime) {
  // return dateTime ? format(new Date(`${dateTime.replace(" ", "T")}Z`), "hh:mm a, d MMM yyyy") : "";
  return dateTime
    ? formatInTimeZone(
        new Date(`${dateTime.replace(" ", "T")}Z`),
        getTimezone(),
        getDTFormat()
      )
    : "";
}

export function getDateTimeText(dateTime) {
  // return format(new Date(`${dateTime.replace(" ", "T")}Z`), "do MMM, p");
  return formatInTimeZone(
    new Date(`${dateTime.replace(" ", "T")}Z`),
    getTimezone(),
    getDTFormat()
  );
}

export function getUnixTimeStampFor(dateTime) {
  return new Date(dateTime).getTime() / 1000;
}

export function getCurrentUnixTimestamp() {
  const timeWithSecs = new Date().getTime() / 1000;
  return timeWithSecs - (timeWithSecs % 100);
}

export function getCurrentDate() {
  return formatInTimeZone(new Date(), getTimezone(), "yyyy-MM-dd");
}

export function getCurrentDatetime() {
    return formatInTimeZone(new Date(),  getTimezone(), "yyyy-MM-dd HH:mm");
}

export function getFormatedDatetime(dateTime) {
    return formatInTimeZone(`${dateTime.replace(" ", "T")}Z`,  getTimezone(), "yyyy-MM-dd HH:mm");
}

export function getDateBefore(days) {
  let newDate = new Date();
  newDate.setDate(newDate.getDate() - days);

  return formatInTimeZone(newDate, getTimezone(), "yyyy-MM-dd");
}

export function getUTCDatetimeStart(dateTime) {
    return clearTnZ(zonedTimeToUtc(dateTime + ' 00:00:00', getTimezone()).toISOString());
}

export function getUTCDatetimeEnd(dateTime) {
  return clearTnZ(
    zonedTimeToUtc(dateTime + " 23:59:59", getTimezone()).toISOString()
  );
}

export function getUTCDatetime(dateTime) {
    return clearTnZ(zonedTimeToUtc(dateTime, getTimezone()).toISOString());
}

export function getTimePeriodsFor(code) {
  const currentDate = getCurrentDate();

  switch (code) {
    case 1:
      return {
        from: currentDate,
        to: currentDate,
      };
    case 2:
      return {
        from: getDateBefore(1),
        to: getDateBefore(1),
      };
    case 3:
      return {
        from: getDateBefore(6),
        to: currentDate,
      };
    case 4:
      return {
        from: getDateBefore(29),
        to: currentDate,
      };
    default:
      return {
        from: "",
        to: "",
      };
  }
}
