import React, { useEffect, useState } from "react";
import {
  Typography,
  LinearProgress,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogContent,
  Divider,
  DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllAccountDetails } from "../../redux/modules/settings";
import CreateAccount from "./components/CreateAccount";
import DialogTitle from "../../components/DialogTitle";
import AddressForm from "../../components/AddressForm/AddressForm";
import {
  getAllCountries,
  updateAccountDetails,
} from "../../redux/modules/settings";
import { removeAccount } from "../../redux/modules/leads";
import { isValidUrl } from "../../utils";
import { Helmet } from "react-helmet";

function formatAddress(address) {
  return address && address.line1
    ? `${address.line1}, ${
        address.line2 ? `${address.line2}, ${address.city}` : `${address.city}`
      }, ${address.state}, ${address.country} - ${address.zipcode}`
    : "";
}

function AccItem({ acc, onEdit, onDel, show }) {
  const addressToShow = formatAddress(acc.address);

  return (
    <Card className="account__card">
      <CardActionArea onClick={() => show(acc)}>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {acc.name}
          </Typography>
          {addressToShow ? (
            <Typography variant="body2" color="textSecondary" component="p">
              {addressToShow}
            </Typography>
          ) : (
            <Typography
              className="empty-message"
              component="div"
              variant="caption"
            >
              No details available
            </Typography>
          )}
          <div>
            <Typography variant="caption">
              {acc.phone ? `Phone: ${acc.phone}` : ""}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <Divider className="action__divider" />
      <CardActions>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => onEdit(acc)}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          size="small"
          style={{ color: "red" }}
          onClick={() => onDel(acc)}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
}

function ContactAccounts() {
  const dispatch = useDispatch();
  const accounts = useSelector((state) => state.settings.detailedAccounts);
  const countries = useSelector((state) => state.settings.countries);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [del, setDelete] = useState(false);
  const [account, setAccount] = useState({});
  const [loadingC, setLoadingC] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [changes, setChanges] = useState(1);
  const [showDetails, setShowDetails] = useState(false);



  useEffect(() => {
    setLoading(true);
    dispatch(getAllAccountDetails())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [changes]);

  useEffect(() => {
    if (!countries.length && !loadingC && edit) {
      setLoadingC(true);
      dispatch(getAllCountries()).then(() => {
        setLoadingC(false);
      });
    }
  }, [edit]);

  const onEditClick = (acc) => {
    setEdit(true);
    setAccount(acc);
  };

  const onDeleteClick = (acc) => {
    setDelete(true);
    setAccount(acc);
  };

  const onCancelDel = () => {
    setDelete(false);
    setAccount({});
  };

  const onCancelUpdate = () => {
    setEdit(false);
    setAccount({});
  };

  const onUpdate = (updatedAccount) => {
    return dispatch(updateAccountDetails(updatedAccount));
  };

  const onRemoveAcc = () => {
    setDeleting(true);
    dispatch(removeAccount(account.account_id))
      .then(() => {
        setChanges(changes + 1);
        setDeleting(false);
        setDelete(false);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  const onAccDetails = (acc) => {
    setShowDetails(true);
    setAccount(acc);
  };

  const onCloseDetails = () => {
    setShowDetails(false);
    setAccount({});
  };

  const addressToShow = formatAddress(account.address);

  return (
    <div className="contact_accounts">
      <Helmet>
        <title>Contact Accounts</title>
        <meta name="description" content="Accounts" />
      </Helmet>
      <div className="page_t_head">
        <div className="page_d_wrapper">
          <Typography className="page_title">Accounts</Typography>
          <CreateAccount />
        </div>
        <div className="lp-deals">{loading ? <LinearProgress /> : null}</div>
        {!accounts.length && !loading ? (
          <Typography
            className="empty-message"
            component="div"
            variant="caption"
          >
            No Contact Accounts has been added yet...
          </Typography>
        ) : null}
      </div>

      <div className="accounts__container">
        {accounts.map((ca, c) => (
          <AccItem
            key={c}
            acc={ca}
            onEdit={onEditClick}
            onDel={onDeleteClick}
            show={onAccDetails}
          />
        ))}
      </div>
      <Dialog
        open={edit}
        onClose={onCancelUpdate}
        scroll="body"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle onClose={onCancelUpdate}>Edit Account</DialogTitle>
        <Divider />
        <DialogContent>
          <AddressForm
            cancel={onCancelUpdate}
            update={onUpdate}
            account={account}
            countries={countries}
            edit
            page
          />
        </DialogContent>
      </Dialog>

      <Dialog open={del} onClose={onCancelDel} maxWidth="xs" fullWidth>
        <DialogTitle>Account Name: {account.name}</DialogTitle>
        <DialogContent>
          <Typography>
            Do you really want to delete the contact account?
          </Typography>
        </DialogContent>
        {deleting ? <LinearProgress /> : null}
        <Divider />
        <DialogActions>
          <Button onClick={onCancelDel} color="primary">
            Cancel
          </Button>
          <Button onClick={onRemoveAcc} style={{ color: "red" }} autoFocus>
            {" "}
            Confirm{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDetails}
        onClose={onCloseDetails}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className="contact-account-title" onClose={onCloseDetails}>
          Account: {account.name}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {addressToShow ? (
            <>
              <Typography variant="h6" style={{fontSize: 16}}>Address</Typography>
              <Typography className="empty-message" component="div">
                {addressToShow}
              </Typography>
            </>
          ) : (
            <Typography
              className="empty-message"
              component="div"
              variant="caption"
            >
              No details available
            </Typography>
          )}
          {account.phone ? (
            <div>
              <Typography variant="h6" style={{fontSize: 16}}>Phone</Typography>
              <Typography className="empty-message" component="div">
                {account.phone}
              </Typography>
            </div>
          ) : null}
          {account.website ? (
            <div>
              <Typography variant="h6" style={{fontSize: 16}}>Website</Typography>
              <Typography className="empty-message" component="div">
                <a
                  target="_blank"
                  href={account.website}
                  className="link_style"
                >
                  {account.website}
                </a>
              </Typography>
            </div>
          ) : null}
          {account.custom && account.custom.length
            ? account.custom.map((cf, o) => (
                <div key={o}>
                  <Typography variant="h6" style={{ fontSize:16 }}>{cf.field_name}</Typography>
                  <Typography className="empty-message" component="div">
                    {isValidUrl(cf.field_value) ? (
                      <a
                        target="_blank"
                        href={cf.field_value}
                        className="link_style"
                      >
                        {cf.field_value}
                      </a>
                    ) : (
                      cf.field_value
                    )}
                  </Typography>
                </div>
              ))
            : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ContactAccounts;
