import React, { useState } from "react";
import {
  Typography,
  ListItem,
  ListItemText,
  Button,
  LinearProgress,
} from "@mui/material";
import AccessControl from "../../../components/AccessControl";
import { useDispatch } from "react-redux";
import { resendInvite } from "../../../redux/modules/staffs";

function getRoleName(roles, roleId) {
  for (let i = 0; i < roles.length; i++) {
    if (roles[i]["role_id"] === roleId) {
      return roles[i]["name"];
    }
  }

  return "";
}

function StaffItem({ staff, roles, onStaffEdit }) {
  const dispatch = useDispatch();
  const [invite, setInvite] = useState(false);
  const [loading, setLoading] = useState(false);

  const onResendClick = (staff) => {
    setLoading(true);
    dispatch(resendInvite(staff.staff_id, staff.email))
      .then(() => {
        setInvite(true);
        setLoading(false);
      })
      .catch(() => {
        setInvite(false);
        setLoading(false);
      });
  };

  return (
    <ListItem className="list_item">
      <ListItemText
        primary={<Typography>{staff.name}</Typography>}
        secondary={
          <Typography variant="caption">
            {staff.email} <span>{staff.active === "0" ? "(invited)" : ""}</span>
          </Typography>
        }
      />
      {staff.active === "0" ? (
        <div>
          <Button
            color="primary"
            onClick={() => onResendClick(staff)}
            disabled={invite}
          >
            {invite ? "Sent" : "Resend Invite"}
          </Button>
          {loading ? <LinearProgress /> : null}
        </div>
      ) : null}

      <Typography style={{ marginRight: 15, marginLeft: 15, color: "#546e7a" }}>
        {getRoleName(roles, staff.role)}
      </Typography>
      <AccessControl name="edit_staff">
        <Button
          variant="outlined"
          className="sec-button"
          onClick={() => onStaffEdit(staff)}
        >
          Edit
        </Button>
      </AccessControl>
    </ListItem>
  );
}

export default StaffItem;
