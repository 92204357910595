import { giveKeysWithValue } from '../../utils';
import { setAlert } from './alert';
import { getUTCDatetime, getUTCDatetimeEnd } from '../../utils/format_date';

// ACTION TYPES
const SET_LEADS_PAGE = 'SET_LEADS_PAGE';
const SET_LEADS_PER_PAGE = 'SET_LEADS_PER_PAGE';
const SET_SELECTED_STATUS = 'SET_SELECTED_STATUS';
const SET_SELECTED_PRIORITY = 'SET_SELECTED_PRIORITY';
const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
const SET_SELECTED_FORM_ID = 'SET_SELECTED_FORM_ID';

const GET_LEADS = 'GET_LEADS';
const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
const GET_LEADS_FAIL = 'GET_LEADS_FAIL';

const UPDATE_A_LEAD = 'UPDATE_A_LEAD';
const UPDATE_A_LEAD_SUCCESS = 'UPDATE_A_LEAD_SUCCESS';
const UPDATE_A_LEAD_FAILED = 'UPDATE_A_LEAD_FAILED';

const GET_LEAD_NOTES = 'GET_LEAD_NOTES';
const GET_LEAD_NOTES_SUCCESS = 'GET_LEAD_NOTES_SUCCESS';
const GET_LEAD_NOTES_FAILED = 'GET_LEAD_NOTES_FAILED';

const POST_LEAD_NOTE = 'POST_LEAD_NOTE';
const POST_LEAD_NOTE_SUCCESS = 'POST_LEAD_NOTE_SUCCESS';
const POST_LEAD_NOTE_FAILED = 'POST_LEAD_NOTE_FAILED';

const RESET_LEAD_NOTES = 'RESET_LEAD_NOTES';

const RESET_LEADS_PAGE = 'RESET_LEADS_PAGE';

const GET_LEAD_DETAILS = 'GET_LEAD_DETAILS';
const GET_LEAD_DETAILS_SUCCESS = 'GET_LEAD_DETAILS_SUCCESS';
const GET_LEAD_DETAILS_FAILED = 'GET_LEAD_DETAILS_FAILED';

const ADD_TO_CONTACTS = 'ADD_TO_CONTACTS';
const ADD_TO_CONTACTS_SUCCESS = 'ADD_TO_CONTACTS_SUCCESS';
const ADD_TO_CONTACTS_FAILED = 'ADD_TO_CONTACTS_FAILED';

const GET_CONTACTS = 'GET_CONTACTS';
const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
const GET_CONTACTS_FAILED = 'GET_CONTACTS_FAILED';

const REMOVE_CONTACTS = 'REMOVE_CONTACTS';
const REMOVE_CONTACTS_SUCCESS = 'REMOVE_CONTACTS_SUCCESS';
const REMOVE_CONTACTS_FAILED = 'REMOVE_CONTACTS_FAILED';

const GET_ACCOUNTS = 'GET_ACCOUNTS';
const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
const GET_ACCOUNTS_FAILED = 'GET_ACCOUNTS_FAILED';

const ADD_NEW_ACCOUNT = 'ADD_NEW_ACCOUNT';
const ADD_NEW_ACCOUNT_SUCCESS = 'ADD_NEW_ACCOUNT_SUCCESS';
const ADD_NEW_ACCOUNT_FAILED = 'ADD_NEW_ACCOUNT_FAILED';

const CREATE_A_CONTACT = 'CREATE_A_CONTACT';
const CREATE_A_CONTACT_SUCCESS = 'CREATE_A_CONTACT_SUCCESS';
const CREATE_A_CONTACT_FAILED = 'CREATE_A_CONTACT_FAILED';

const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';

const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';

const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
const REMOVE_ACCOUNT_SUCCESS = 'REMOVE_ACCOUNT_SUCCESS';
const REMOVE_ACCOUNT_FAILED = 'REMOVE_ACCOUNT_FAILED';

const SET_SELECTED_ACTIVE = 'SET_SELECTED_ACTIVE';

const UPDATE_ACTIVE_STATUS = 'UPDATE_ACTIVE_STATUS';
const UPDATE_ACTIVE_STATUS_SUCCESS = 'UPDATE_ACTIVE_STATUS_SUCCESS';
const UPDATE_ACTIVE_STATUS_FAILED = 'UPDATE_ACTIVE_STATUS_FAILED';

const UPDATE_A_CONTACT = 'UPDATE_A_CONTACT';
const UPDATE_A_CONTACT_SUCCESS = 'UPDATE_A_CONTACT_SUCCESS';
const UPDATE_A_CONTACT_FAILED = 'UPDATE_A_CONTACT_FAILED';

const UPDATE_L_DATA = 'UPDATE_L_DATA';
const UPDATE_L_DATA_S = 'UPDATE_L_DATA_S';
const UPDATE_L_DATA_F = 'UPDATE_L_DATA_F';

const GET_FL = 'GET_FL';
const GET_FL_S = 'GET_FL_S';
const GET_FL_F = 'GET_FL_F';

const ASSIGN_STAFF = 'ASSIGN_STAFF';
const ASSIGN_STAFF_S = 'ASSIGN_STAFF_S';
const ASSIGN_STAFF_F = 'ASSIGN_STAFF_F';

const REMOVE_ASSIGN = 'REMOVE_ASSIGN';
const REMOVE_ASSIGN_S = 'REMOVE_ASSIGN_S';
const REMOVE_ASSIGN_F = 'REMOVE_ASSIGN_F';

const GET_A_CONTACT = 'GET_A_CONTACT';
const GET_A_CONTACT_S = 'GET_A_CONTACT_S';
const GET_A_CONTACT_F = 'GET_A_CONTACT_F';

const SET_FORM_PAGE = 'SET_FORM_PAGE';

const SET_SELECTED_N_A = 'SET_SELECTED_N_A';

const CLEAR_LEAD_TL = 'CLEAR_LEAD_TL';
const SET_LTL_PAGE = 'SET_LTL_PAGE';

const GET_LEAD_T = 'GET_LEAD_T';
const GET_LEAD_T_SUCCESS = 'GET_LEAD_T_SUCCESS';
const GET_LEAD_T_FAILED = 'GET_LEAD_T_FAILED';

const GET_DF = 'GET_DF';
const GET_DF_SUCCESS = 'GET_DF_SUCCESS';
const GET_DF_FAIL = 'GET_DF_FAIL';

const CHANGE_FILTER = 'CHANGE_FILTER';
const RESET_D_F = 'RESET_D_F';

const UPDATE_LAST_ACTIVITY = "UPDATE_LAST_ACTIVITY";

const GET_LEADS_BD = 'GET_LEADS_BD';
const GET_LEADS_BD_S = 'GET_LEADS_BD_S';
const GET_LEADS_BD_F = 'GET_LEADS_BD_F';

const UP_CONTACTS = 'UP_CONTACTS';
const UP_CONTACTS_S = 'UP_CONTACTS';
const UP_CONTACTS_F = 'UP_CONTACTS_F';

const SET_SELECTED_ACCOUNT_CONTACT = 'SET_SELECTED_ACCOUNT_CONTACT';

const GET_LAST_ACT = 'GET_LAST_ACT';
const GET_LAST_ACT_SUCCESS = 'GET_LAST_ACT_SUCCESS';
const GET_LAST_ACT_FAIL = 'GET_LAST_ACT_FAIL';

const GET_LAST_CON_ACT = 'GET_LAST_CON_ACT';
const GET_LAST_CON_ACT_SUCCESS = 'GET_LAST_CON_ACT_SUCCESS';
const GET_LAST_CON_ACT_FAIL = 'GET_LAST_CON_ACT_FAIL';

const SET_ACT_LEAD = 'SET_ACT_LEAD';

const GET_E_DETAILS = 'GET_E_DETAILS';
const GET_E_DETAILS_S = 'GET_E_DETAILS_S';
const GET_E_DETAILS_F = 'GET_E_DETAILS_F';

const GET_ES = 'GET_ES';
const GET_ES_S = 'GET_ES_S';
const GET_ES_F = 'GET_ES_F';

const ALTER_L_NAME = 'ALTER_L_NAME';
const SET_SELECTED_DATES = 'SET_SELECTED_DATES';
const SET_DATE_FILTER = 'SET_DATE_FILTER';

const UPLOAD_LEADS = 'UPLOAD_LEADS';
const UPLOAD_LEADS_S = 'UPLOAD_LEADS_S';
const UPLOAD_LEADS_F = 'UPLOAD_LEADS_F';

const GET_SCHED = 'GET_SCHED';
const GET_SCHED_S = 'GET_SCHED_S';
const GET_SCHED_F = 'GET_SCHED_F';

const SET_LEAD_STATUS_AND_TYPE_EDITABLE = 'SET_LEAD_STATUS_AND_TYPE_EDITABLE';
const LEAD_UPDATE_SAVE = 'LEAD_UPDATE_SAVE';

// INITIAL STATE
const initialState = {
  leadsList: [],
  notesList: [],
  page: 0,
  rowsPerPage: 10,
  selectedFormId: "",
  selectedStatus: "",
  selectedActive: "1",
  selectedPriority: "",
  serachQuery: "",
  loading: false,
  total: 0,
  searchQuery: "",
  leadDetails: null,
  contacts: [],
  totalContacts: 0,
  accounts: [],
  selectedAccount: "",
  saForContact: "",
  selectedNewAccount: "",
  formsList: [],
  formPage: 0,
  totalForms: 0,
  noteCount: 0,
  contactDetails: null,
  tlPage: 0,
  leadTimeline: [],
  averageResponseRate: 0,
  timelineCount: 0,
  dynamicFilters: [],
  filterValue: {},
  leadForDownload: [],
  activeLead: null,
  emails: [],
  selectedDates: {
    from: "",
    to: ""
  },
  dateFilter: 0
};


// REDUCER
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LEADS_PAGE:
      return {
        ...state,
        page: action.page
      };
    case RESET_LEADS_PAGE:
      return {
        ...state,
        page: 0
      };
    case SET_LEADS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery
      };
    case SET_SELECTED_PRIORITY:
      return {
        ...state,
        selectedPriority: action.selectedPriority,
        page: 0
      };
    case SET_SELECTED_STATUS:
      return {
        ...state,
        selectedStatus: action.selectedStatus,
        page: 0
      };
    case SET_SELECTED_FORM_ID:
      return {
        ...state,
        selectedFormId: action.selectedFormId,
        page: 0
      };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.accountId
      };
    case SET_SELECTED_ACCOUNT_CONTACT:
      return {
        ...state,
        saForContact: action.accountId
      };
    case SET_SELECTED_N_A:
      return {
        ...state,
        selectedNewAccount: action.accountId
      };
    case SET_SELECTED_ACTIVE:
      return {
        ...state,
        selectedActive: action.active_status
      };
    case GET_ACCOUNTS:
      return {
        ...state,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: payload.result || []
      };
    case GET_LEAD_DETAILS:
      return {
        ...state,
        // leadDetails: null,
        loading: true
      };
    case GET_A_CONTACT_S:
      return {
        ...state,
        contactDetails: payload || null
      };
    case GET_DF_SUCCESS:
      return {
        ...state,
        dynamicFilters: payload.result && payload.result.filters || []
      };
    case CHANGE_FILTER:
      return {
        ...state,
        filterValue: giveKeysWithValue({
          ...state.filterValue,
          [action.filter_name]: action.filter_value
        })
      };
    case RESET_D_F:
      return {
        ...state,
        filterValue: {},
        page: 0
      };
    case UPDATE_ACCOUNT:
    case REMOVE_ACCOUNT:
    case POST_LEAD_NOTE:
    case GET_LEAD_NOTES:
    case GET_CONTACTS:
    case GET_LEADS:
        return {
            ...state,
            loading: true
        };
    case GET_LEAD_DETAILS_SUCCESS:
      const newLeads = state.leadsList.map(ll => ll.lead_id == payload.lead_id ? ({...ll, ...payload}) : ll);

      return {
        ...state,
        leadsList: newLeads,
        leadDetails: payload || null,
        loading: false
      };
    case GET_LEAD_NOTES_SUCCESS:
      return {
        ...state,
        notesList: payload.notes || [],
        noteCount: payload.counts || 0,
        loading: false
      };
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leadsList: payload.result.map((res) => ({ ...res, isStatusAndTypeEditable: false })) || [],
        total: Number(payload.count || "0"),
        loading: false
      };
    case GET_LAST_ACT:
      return {
        ...state,
        loadingAcitviy: true
      };
    case GET_LAST_ACT_SUCCESS:
      const result = payload.result;
      const leadsList = [
        ...(state.leadsList.map((ll) => {
          ll.last_activity = result[ll.lead_id] && result[ll.lead_id]["activity"] || "";

          return ll;
        }))
      ];

      return {
        ...state,
        loadingAcitviy: false,
        leadsList
      };
    case GET_LAST_ACT_FAIL:
      return {
        ...state,
        loadingAcitviy: false
      };
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: payload.result || [],
        totalContacts: Number(payload.count || "0"),
        loading: false
      };
    case GET_LAST_CON_ACT_SUCCESS:
      const contactResult = payload.result;
      const contacts = [
        ...(state.contacts.map((cl) => {
          cl.last_activity = contactResult[cl.contact_id] && contactResult[cl.contact_id]["activity"] || "";

          return cl;
        }))
      ];

      return {
        ...state,
        contacts
      };
    case RESET_LEAD_NOTES:
      return {
        ...state,
        notesList: []
      };
    case GET_FL_S:
      return {
        ...state,
        formsList: payload.result || [],
        totalForms: Number(payload.count || "0"),
      };
    case SET_FORM_PAGE:
      return {
        ...state,
        formPage: action.page
      };
    case GET_LEAD_T_SUCCESS:
      return {
          ...state,
          leadTimeline: [
              ...state.leadTimeline,
              ...(payload && payload.result || [])
          ],
          averageResponseRate: payload.averageResponseRate || 0,
          timelineCount: payload.count || 0
      };
    case CLEAR_LEAD_TL:
      return {
          ...state,
          leadTimeline: [],
          timelineCount: 0
      };
    case SET_LTL_PAGE:
      return {
          ...state,
          tlPage: action.page
      };
    case UPDATE_ACCOUNT_SUCCESS:
    case REMOVE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_FAILED:
    case REMOVE_ACCOUNT_FAILED:
    case GET_ACCOUNTS_FAILED:
    case GET_CONTACTS_FAILED:
    case GET_LEAD_DETAILS:
    case GET_LEAD_NOTES_FAILED:
    case POST_LEAD_NOTE_SUCCESS:
    case POST_LEAD_NOTE_FAILED:
    case GET_LEADS_FAIL:
        return {
            ...state,
            loading: false
        };
    case UPDATE_L_DATA_S:
      return {
        ...state,
      };
    case UPDATE_LAST_ACTIVITY:
      if (action.leadId) {

        const newList = state.leadsList.map(lead => lead.lead_id == action.leadId ? { ...lead, last_activity: action.activity || "" } : lead);
  
        return {
          ...state,
          leadsList: newList
        };
      } else if (action.contactId) {
        const newList = state.contacts.map(contact => contact.contact_id == action.contactId ? { ...contact, last_activity: action.activity || "" } : contact);
  
        return {
          ...state,
          contacts: newList
        };
      }
    case GET_LEADS_BD_S:
      return {
        ...state,
        leadsForDownload: payload.result || []
      };
    case SET_ACT_LEAD:
      return {
        ...state,
        activeLead: action.leadId
      };
    case GET_ES_S:
      return {
        ...state,
        emails: payload.result || []
      };
    case ALTER_L_NAME:
        const newList = state.leadsList.map(lead => action.leadId && lead.lead_id == action.leadId ? { ...lead, name: action.name } : lead);
      return {
        ...state,
        leadsList: newList
      };
    case SET_SELECTED_DATES:
      return {
        ...state,
        selectedDates: { from: action.from || "", to: action.to || "" }
      };
    case SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.code || 0
      };
    case GET_SCHED_S:
      const scheduledResult = payload.result;
      const leadsLists = [
        ...(state.leadsList.map((ll) => {
          ll.scheduled = (scheduledResult.find(sr => sr.lead_id === ll.lead_id) || { scheduled_items: 0 }).scheduled_items;

          return ll;
        }))
      ];

      return {
        ...state,
        loadingAcitviy: false,
        leadsList: leadsLists
      };
    case SET_LEAD_STATUS_AND_TYPE_EDITABLE:
      return {
        ...state,
        leadsList: state.leadsList.map((lead) => {
          if (lead.lead_id === action.lead_id) {
            return {
              ...lead,
              isStatusAndTypeEditable: action.status
            }
          }
          return lead

        })
      }
    default:
      return state;
  }
}

// ACTION CREATORS
export function setLeadsPage(page) {
  return {
    type: SET_LEADS_PAGE,
    page
  };
}

export function resetLeadsPage() {
  return {
    type: RESET_LEADS_PAGE
  };
}

export function setLeadsPerPage(rowsPerPage) {
  return {
    type: SET_LEADS_PER_PAGE,
    rowsPerPage
  };
}

export function setSelectedStatus(selectedStatus) {
    return {
        type: SET_SELECTED_STATUS,
        selectedStatus
    };
}

export function setSelectedProirity(selectedPriority) {
    return {
        type: SET_SELECTED_PRIORITY,
        selectedPriority
    };
}

export function setSearchQuery(searchQuery) {
    return {
        type: SET_SEARCH_QUERY,
        searchQuery
    };
}

export function setSelectedFormId(selectedFormId) {
  return {
      type: SET_SELECTED_FORM_ID,
      selectedFormId
  };
}

export function getLeads(formId, order) {
  return (dispatch, getState) => {
    const { leads: { page, rowsPerPage, selectedPriority, selectedStatus, selectedActive, searchQuery, filterValue, selectedDates, dateFilter } } = getState();

    return dispatch({
      types: [GET_LEADS, GET_LEADS_SUCCESS, GET_LEADS_FAIL],
      promise: fetch => fetch.get('api/formlead', {
        params: {
            form_id: formId == "all" ? "" : formId,
            priority_status: selectedPriority,
            lead_status: selectedStatus,
            active_status: selectedActive == 2 ? 1 : selectedActive,
            ...(selectedActive == 2 ? {lead_assign: 1} : {}),
            searchvalue: searchQuery,
            limit: rowsPerPage,
            offset: page,
            order,
            ...(selectedDates.from && selectedDates.to ? { from_date: getUTCDatetime(selectedDates.from), to_date: getUTCDatetimeEnd(selectedDates.to) } : {}),
            ...filterValue
        }
      })
    });
  }
}

export function setFormPage(page) {
  return {
    type: SET_FORM_PAGE,
    page
  };
}

export function getFormsList(page) {
  return (dispatch, getState) => {
    const { leads: { formPage } } = getState();

    return dispatch({
      types: [GET_FL, GET_FL_S, GET_FL_F],
      promise: fetch => fetch.get('api/formlisting', {
        params: {
          limit: 10,
          offset: page ? page - 1  : formPage
        }
      })
    });
  }
}

export function getFormsAndSetId(flag) {
  return (dispatch, getState) => {
    return dispatch(getFormsList()).then(() => {
      const { leads: { formsList } } = getState();
  
      if (formsList[0] && !flag) {
        dispatch(setSelectedFormId(formsList[0]["id"]));
      }
    });
  }
}

export function updateALead(leadFields) {
  return {
    types: [UPDATE_A_LEAD, UPDATE_A_LEAD_SUCCESS, UPDATE_A_LEAD_FAILED],
    promise: fetch => fetch.put('/api/formlead/leadupdate', { 
      data: leadFields
     })
  };
}

export function getLeadNotes(lead_id, contact_id, limit, offset) {
    return {
      types: [GET_LEAD_NOTES, GET_LEAD_NOTES_SUCCESS, GET_LEAD_NOTES_FAILED],
      promise: fetch => fetch.get('api/Contactnotes', { 
        params: {
          lead_id,
          contact_id,
          limit,
          offset
        }
      })
    };
}

export function resetLeadNotes() {
  return {
    type: RESET_LEAD_NOTES
  };
}

export function postLeadNotes(lead_id, contact_id, note, type = "", lead_name, limit, offset) {
  return dispatch => dispatch({
    types: [POST_LEAD_NOTE, POST_LEAD_NOTE_SUCCESS, POST_LEAD_NOTE_FAILED],
    promise: fetch => fetch.post('api/Contactnotes', {
      data: {
        lead_id,
        contact_id,
        lead_note: note,
        type,
        lead_name
      }
    })
  }).then(() => {
    dispatch(setAlert(`Note added successfully for the Lead ${lead_id}`, "success"));

    return dispatch(getLeadNotes(lead_id, contact_id, limit, offset));
  });
}

export function getLeadDetails(lead_id) {
  return {
    types: [GET_LEAD_DETAILS, GET_LEAD_DETAILS_SUCCESS, GET_LEAD_DETAILS_FAILED],
    promise: fetch => fetch.get('api/leadview', { 
      params: {
        lead_id
      }
    })
  };
}

export function getSchedules(lead_ids) {
  return {
    types: [GET_SCHED, GET_SCHED_S, GET_SCHED_F],
    promise: fetch => fetch.get('api/lead_schedule', {
      params: {
        leads: lead_ids.toString()
      }
    })
  };
}

export function getLastActivity(lead_ids) {
  return dispatch => dispatch({
    types: [GET_LAST_ACT, GET_LAST_ACT_SUCCESS, GET_LAST_ACT_FAIL],
    promise: fetch => fetch.get('api/Lead_activity', {
      params: {
        lead_ids: lead_ids.toString()
      }
    })
  }).then(() =>  dispatch(getSchedules(lead_ids)));
}

export function getLastContactActivity(contact_ids) {
  return {
    types: [GET_LAST_CON_ACT, GET_LAST_CON_ACT_SUCCESS, GET_LAST_CON_ACT_FAIL],
    promise: fetch => fetch.get('api/Contact_activity', {
      params: {
        contact_ids: contact_ids.toString()
      }
    })
  };
}

export function getContacts(contact) {
  return (dispatch, getState) => {
    const { leads: { page, rowsPerPage, selectedFormId, selectedAccount, saForContact, searchQuery } } = getState();
    return dispatch({
      types: [GET_CONTACTS, GET_CONTACTS_SUCCESS, GET_CONTACTS_FAILED],
      promise: fetch => fetch.get('api/Contact', { 
        params: {
          form_id: selectedFormId === "all" ? "" : selectedFormId,
          account_id: contact ? saForContact : selectedAccount,
          searchvalue: searchQuery,
          limit: rowsPerPage,
          offset: page
        }
      })
    }).then((res) => {
      
      if (res.result) {
        dispatch(getLastContactActivity(res.result.map(cl => cl.contact_id)));
      }

      return res;
    });
  };
}

export function addToContacts(lead_id, account_id, emails, phones, name, deal_id, lead_name) {
  return dispatch => dispatch({
    types: [ADD_TO_CONTACTS, ADD_TO_CONTACTS_SUCCESS, ADD_TO_CONTACTS_FAILED],
    promise: fetch => fetch.put('api/Contact', { 
      data: {
        lead_id,
        account_id,
        contact_status: "1",
        email_ids: emails,
        phone_numbers: phones,
        name,
        deal_id,
        lead_name
      }
    })
  }).then(() => {
    dispatch(setAlert(`The Lead #${lead_id} has been converted to contact.`, "success"));
  });
}

export function createAContact(account_id, emails, phones, name) {
  return dispatch => dispatch({
    types: [CREATE_A_CONTACT, CREATE_A_CONTACT_SUCCESS, CREATE_A_CONTACT_FAILED],
    promise: fetch => fetch.post('api/Contact', { 
      data: {
        account_id,
        name,
        email_ids: emails,
        phone_numbers: phones,
      }
    })
  }).then(() => {
    dispatch(setAlert(`A new contact created successfully.`, "success"));

    return dispatch(getContacts(true));
  }).catch(() => {
    dispatch(setAlert(`Error creating the contact.`, "error"));

    return Promise.reject();
  });
}

export function updateAContact(contact_id, contact_name, emails, mobiles, account_id) {
  return dispatch => dispatch({
    types: [UPDATE_A_CONTACT, UPDATE_A_CONTACT_SUCCESS, UPDATE_A_CONTACT_FAILED],
    promise: fetch => fetch.put('api/Contactslist', {
      data: {
        contact_id, ...(contact_name ? {contact_name} : {}), ...(emails ? {emails} : {}), ...(mobiles ? {mobiles} : {}), ...(account_id ? {account_id} : {})
      }
    })
  }).then(() => dispatch(getContacts(true)));
}

export function removeContacts(contact_id) {
  return dispatch => dispatch({
    types: [REMOVE_CONTACTS, REMOVE_CONTACTS_SUCCESS, REMOVE_CONTACTS_FAILED],
    promise: fetch => fetch.delete('api/Contact', {
      params: {
        contact_id
      }
    })
  }).then(() => dispatch(getContacts(true)));
}

export function getAllAccount() {
    return {
        types: [GET_ACCOUNTS, GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_FAILED],
        promise: fetch => fetch.get('api/Account')
    }
}

export function addAccount(name) {
    return (dispatch, getState) => dispatch({
        types: [ADD_NEW_ACCOUNT, ADD_NEW_ACCOUNT_SUCCESS, ADD_NEW_ACCOUNT_FAILED],
        promise: fetch => fetch.post('api/Account', { data: { name } })
    }).then((res) => {
      if (res.status === 1) {
        dispatch(setAlert(`New account (${name}) created successfully.`, "success"));

        return dispatch(getAllAccount());
      } else if (res.status === 0) {
        dispatch(setAlert(`[Error] ${res.message}`, "error"));

        return Promise.reject();
      }
    }).then(() => getState().leads.accounts);
}

export function updateAccount(account) {
  return (dispatch) => dispatch({
      types: [UPDATE_ACCOUNT, UPDATE_ACCOUNT_SUCCESS, UPDATE_ACCOUNT_FAILED],
      promise: fetch => fetch.put('api/Account', { data: account })
  }).then((res) => {
    if (res.status === 1) {
      dispatch(setAlert(`Account updated successfully.`, "success"));

      return dispatch(getAllAccount());
    } else if (res.status === 0) {
      dispatch(setAlert(`[Error] ${res.message}`, "error"));

      return Promise.reject();
    }
  });
}

export function removeAccount(account_id) {
  return (dispatch) => dispatch({
      types: [REMOVE_ACCOUNT, REMOVE_ACCOUNT_SUCCESS, REMOVE_ACCOUNT_FAILED],
      promise: fetch => fetch.delete('api/Accountdetails', { data: { account_id } })
  }).then(() => dispatch(getAllAccount()));
}

export function setSelectedAccount(accountId) {
  return {
    type: SET_SELECTED_ACCOUNT,
    accountId
  };
}

export function setSelectedAccountForContact(accountId) {
  return {
    type: SET_SELECTED_ACCOUNT_CONTACT,
    accountId
  };
}

export function setSelectedNewAccount(accountId) {
  return {
    type: SET_SELECTED_N_A,
    accountId
  };
}

export function getAllAccountAndSetFirst() {
  return (dispatch, getState) => dispatch(getAllAccount()).then(() => {
    const { leads: { accounts } } = getState();
    // if (accounts[0]) {
    //   dispatch(setSelectedAccount(accounts[0]["account_id"]));
    // }
  })
}

export function setSelectedActiveStatus(active_status) {
  return {
    type: SET_SELECTED_ACTIVE,
    active_status
  }
}

export function updateActiveStatus(lead_id, lead_name, active_status) {
  return (dispatch, getState) => dispatch({
    types: [UPDATE_ACTIVE_STATUS, UPDATE_ACTIVE_STATUS_SUCCESS, UPDATE_ACTIVE_STATUS_FAILED],
    promise: fetch => fetch.put('api/formlead/leadupdate', { 
      data: {
        lead_id,
        active_status,
        lead_name
      }
     })
  }).then(() => {
    const { leads: { selectedFormId } } = getState();
    
    dispatch(setAlert(`The Lead #${lead_id} ${ active_status === "0" ? "archieved" : "activated" } successfully`, "success"));

    return dispatch(getLeads(selectedFormId)).then(({ result }) => dispatch(getLastActivity(result.map(r => r.lead_id))));
  }).catch(() => {
    dispatch(setAlert(`The Lead #${lead_id} updation error`, "error"));
  });
}

export function changeActiveStatusOfLeads(leads, active_status) {
  return (dispatch, getState) => dispatch({
    types: [UPDATE_ACTIVE_STATUS, UPDATE_ACTIVE_STATUS_SUCCESS, UPDATE_ACTIVE_STATUS_FAILED],
    promise: fetch => fetch.put('api/Lead_archive', {
      data: {
        lead_id: leads,
        active_status
      }
    })
  }).then(() => {
    const { leads: { selectedFormId } } = getState();
    
    dispatch(setAlert(`Some leads are ${ active_status === "0" ? "archieved" : "activated" } successfully`, "success"));

    return dispatch(getLeads(selectedFormId)).then(({ result }) => dispatch(getLastActivity(result.map(r => r.lead_id))));
  }).catch(() => {
    dispatch(setAlert(`There is an lead updation error`, "error"));
  });;
}

function alterLeadName(leadId, name) {
  return {
    type: ALTER_L_NAME,
    leadId,
    name
  };
}

export function updateLeadData(leadData, isNameField) {
  return dispatch => dispatch({
    types: [UPDATE_L_DATA, UPDATE_L_DATA_S, UPDATE_L_DATA_F],
    promise: fetch => fetch.put('api/Leadedit', {
      data: leadData
    })
  }).then(() => {
      if (isNameField) {
        dispatch(alterLeadName(leadData.lead_id, leadData.fields_edit[0]["field_value"]));
      }

    return dispatch(getLeadDetails(leadData.lead_id));
  });
}

export function assignAStaff(lead_id, lead_name, staff_id, staffname) {
  return (dispatch, getState) => dispatch({
    types: [ASSIGN_STAFF, ASSIGN_STAFF_S, ASSIGN_STAFF_F],
    promise: fetch => fetch.post('api/Lead_assign', { data: { lead_id, staff_id, lead_name } })
  }).then(() => {
    const { leads: { selectedFormId } } = getState();

    dispatch(setAlert(`The Lead #${lead_id} has been assigned to ${staffname} successfully`, "success"));

    return dispatch(getLeads(selectedFormId)).then(({ result }) => dispatch(getLastActivity(result.map(r => r.lead_id))));
  }).catch(() => {
    return dispatch(setAlert(`Error assigning the staff to lead.`, "error"));
  });
}

export function assignANewStaff(lead_id, lead_name, staff_id, staffname) {
  return (dispatch, getState) => dispatch({
    types: [ASSIGN_STAFF, ASSIGN_STAFF_S, ASSIGN_STAFF_F],
    promise: fetch => fetch.put('api/Lead_assign', { data: { lead_id, staff_id, lead_name } })
  }).then(() => {
    const { leads: { selectedFormId } } = getState();

    dispatch(setAlert(`The Lead #${lead_id} has been assigned to ${staffname} successfully`, "success"));

    return dispatch(getLeads(selectedFormId)).then(({ result }) => dispatch(getLastActivity(result.map(r => r.lead_id))));
  }).catch(() => {
    return dispatch(setAlert(`Error assigning the staff to lead.`, "error"));
  });
}

export function removeAssign(lead_id) {
  return (dispatch, getState) => dispatch({
    types: [REMOVE_ASSIGN, REMOVE_ASSIGN_S, REMOVE_ASSIGN_F],
    promise: fetch => fetch.delete('api/Lead_assign', { data: { lead_id } })
  }).then(() => {
    const { leads: { selectedFormId } } = getState();

    dispatch(setAlert(`The Lead #${lead_id} has been successfully assigned to none`, "success"));

    return dispatch(getLeads(selectedFormId)).then(({ result }) => dispatch(getLastActivity(result.map(r => r.lead_id))));
  }).catch(() => {
    return dispatch(setAlert(`Error assigning the staff to lead.`, "error"));
  });
}

export function getAContactDetails(contact_id) {
  return {
    types: [GET_A_CONTACT, GET_A_CONTACT_S, GET_A_CONTACT_F],
    promise: fetch => fetch.get('api/Contact_details', {
      params: {
        contact_id
      }
    })
  };
}

export function getLeadTimeline(lead_id, limit, offset) {
  return {
      types: [GET_LEAD_T, GET_LEAD_T_SUCCESS, GET_LEAD_T_FAILED],
      promise: fetch => fetch.get('api/Lead_timeline', {
          params: { lead_id, limit, offset }
      })
  };
}

export function getContactTimeline(contact_id, limit, offset) {
  return {
      types: [GET_LEAD_T, GET_LEAD_T_SUCCESS, GET_LEAD_T_FAILED],
      promise: fetch => fetch.get('api/Contact_timeline', {
          params: { contact_id, limit, offset }
      })
  };
}

export function clearLeadTL() {
  return {
      type: CLEAR_LEAD_TL
  };
}

export function setLeadTLPage(page) {
  return {
      type: SET_LTL_PAGE,
      page
  };
}

export function getDynamicFilters(form_id) {
 return {
  types: [GET_DF, GET_DF_SUCCESS, GET_DF_FAIL],
  promise: fetch => fetch.get('api/Advance_filter', {
    params: {
      form_id
    }
  })
 };
}

export function changeFilter(filter_id, filter_value) {
  return {
    type: CHANGE_FILTER,
    filter_name: `filter[${filter_id}]`,
    filter_value
  };
}

export function resetDFilter() {
  return {
    type: RESET_D_F
  }
}

export function updateLastActivity(leadId, contactId, activity) {
  return {
    type: UPDATE_LAST_ACTIVITY,
    leadId,
    contactId,
    activity
  };
}

export function getLeadsBetweenDates(from_date, to_date, form_id) {
  return {
    types: [GET_LEADS_BD, GET_LEADS_BD_S, GET_LEADS_BD_F],
    promise: fetch => fetch.get('api/Lead_filter', {
      params: {
        form_id,
        from_date,
        to_date
      }
    })
  };
}

export function uploadContacts(contacts) {
  return dispatch => dispatch({
    types: [UP_CONTACTS, UP_CONTACTS_S, UP_CONTACTS_F],
    promise: fetch => fetch.post('api/Contact_import', {
      data: {
        contacts
      }
    })
  }).then((res) => {
    if (res.status === 1) {
      dispatch(setAlert(`The contacts has been successfully uploaded`, "success"));
    }

    return res;
  }).catch(() => {
    dispatch(setAlert(`Error in uploading contacts csv`, "error"));

    return Promise.reject();
  });
}

export function setActiveLead(leadId) {
  return {
    type: SET_ACT_LEAD,
    leadId
  }
}

export function getEmailDetails(activity_id) {
  return {
    types: [GET_E_DETAILS, GET_E_DETAILS_S, GET_E_DETAILS_F],
    promise: fetch => fetch.get('api/Followup_details', {
      params: {
        activity_id
      }
    })
  };
}

export function getEmailsForLeads(leads) {
  return {
    types: [GET_ES, GET_ES_S, GET_ES_F],
    promise: fetch => fetch.get('api/Emails_for_leads', {
      params: {
        leads: leads.toString()
      }
    })
  }
}

export function setSelectedDates(from, to) {
  return {
    type: SET_SELECTED_DATES,
    from,
    to
  }
}

export function setDateFilter(code) {
  return {
    type: SET_DATE_FILTER,
    code
  }
}

export function uploadLeads(data) {
  return dispatch => dispatch({
    types: [UPLOAD_LEADS, UPLOAD_LEADS_S, UPLOAD_LEADS_F],
    promise: fetch => fetch.post('api/Lead_upload', {
      data
    })
  }).then((res) => {
    if (res.status === 1) {
      dispatch(setAlert(`Leads has been successfully imported`, "success"));
    }

    return res;
  }).catch((err) => {
    const errMessage = err.response && err.response.data ? err.response.data.message : "";
    dispatch(setAlert(errMessage ? `Error: ${errMessage}` : `Error in importing leads`, "error"));

    return Promise.reject();
  });
}

export function setLeadStatusAndTypeEditable({ lead_id, status }) {
  return {
    type: SET_LEAD_STATUS_AND_TYPE_EDITABLE,
    lead_id,
    status
  }
}

export function leadUpdateSave(leadDetails) {
  return {
    types: LEAD_UPDATE_SAVE,
    promise: fetch => fetch.put('/api/Lead_update_save', { 
      data: leadDetails
     })
  };
}