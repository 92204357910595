import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  login,
  resetError,
  submitResetPasswordRequest,
  resendPREmail,
} from "../../redux/modules/auth";
import {
  Typography,
  Container,
  Paper,
  Checkbox,
  TextField,
  Button,
  FormControlLabel,
  LinearProgress,
  Box,
} from "@mui/material";

const Login = ({
  login,
  isAuthenticated,
  clientName,
  error,
  loading,
  ...props
}) => {
  const [checked, setChecked] = useState(
    !!Number(localStorage.getItem("remember")) || false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [forget, setForget] = useState(false);
  const [sent, setSent] = useState(false);
  const [requestError, setError] = useState("");
  const [formData, setFormData] = useState({
    email: localStorage.getItem("email") || "",
    password: "",
  });
  const [signing, setSigning] = useState(false);
  const [sendingMail, setSending] = useState(false);
  const accountLocked = useSelector((state) => state.auth.accountLocked);
  const failedAttempt = useSelector((state) => state.auth.failedAttempt);
  const [resetMailExist, setResetMailExist] = useState(false);

  const { email, password } = formData;


  const onChange = (e) => {
    if (error) {
      props.resetError();
    }
    const value = e.target.value.trim();
    setError("");
    setFormData({ ...formData, [e.target.name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (checked) {
      localStorage.setItem("email", email);
    } else if (localStorage.getItem("email") === email) {
      localStorage.setItem("email", "");
    }

    setSigning(true);
    login(email, password)
      .then(() => {
        setSigning(false);
      })
      .catch(() => {
        setSigning(false);
      });
  };

  const onCheck = () => {
    setChecked(!checked);
    localStorage.setItem("remember", checked ? "0" : "1");
  };

  const onForgetClick = () => {
    setForget(true);
  };

  const onEmailSubmit = () => {
    setIsLoading(true);
    props.submitResetPasswordRequest(email).then((response) => {
      if (response.status === 1) {
        setIsLoading(false);
        setSent(true);
        setError("");
        if (
          response.message ===
          "Reset password already sends to your email account"
        ) {
          setResetMailExist(response.message);
        }
      } else {
        setIsLoading(false);
        setSent(false);
        setError(response.message);
      }
    });
  };

  const onGoToLogin = () => {
    setSent(false);
    setForget(false);
    setFormData({ email: "", password: "" });
    setError("");
  };

  const onResendEmail = () => {
    setSending(true);
    props
      .resendPREmail(email)
      .then(() => {
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      });
  };

  if (isAuthenticated) return <Navigate to="/leads" />;

  // if (isAuthenticated) return <Redirect to={location.state && location.state.from || '/'} />;

  return (
    <div className="auth" style={{ backgroundImage: "url(/login-bg.png)" }}>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="login page" />
      </Helmet>
      <Container maxWidth="xs">
        {/* <CssBaseline /> */}
        <div className="logodiv">
          <Box
            component="img"
            style={{ height: 81, marginBottom: 20 }}
            src="/logo-FL.png"
          />
        </div>
        <Paper className="auth_paper">
          <Typography variant="h6" className="auth__lead">
            {forget ? (
              "Reset your password"
            ) : (
              <span>
                Sign in to{" "}
                <span className="value__chip">
                  {clientName || "Forms Leads"}
                </span>
              </span>
            )}
          </Typography>
          {sent ? (
            <div className="auth__form">
              <Typography className="password_sent_message">
                {resetMailExist
                  ? `${resetMailExist}. If it doesn’t appear click on the resend email button`
                  : " Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder."}
              </Typography>
              <div className="btn-col">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onGoToLogin}
                >
                  Return to sign in
                </Button>
                <Button
                  color="primary"
                  onClick={onResendEmail}
                  disabled={sendingMail}
                >
                  Resend email
                </Button>
              </div>
            </div>
          ) : (
            <>
              {signing ? <LinearProgress className="lp-login" /> : null}
              <form
                className={"auth__form" + (signing ? " loading__auth" : "")}
                onSubmit={(e) => onSubmit(e)}
              >
                {/* <div className="auth__form__group"> */}
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  className="auth__form__input"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={(e) => onChange(e)}
                  required
                  disabled={isLoading}
                />
                {requestError ? (
                  <Typography className="password_sent_error">
                    {requestError}
                  </Typography>
                ) : null}
                {isLoading ? <LinearProgress /> : null}
                {/* </div> */}
                {forget ? (
                  <Button
                    color="primary"
                    onClick={onEmailSubmit}
                    disabled={isLoading || !email}
                  >
                    Submit
                  </Button>
                ) : (
                  <div className="auth__form__group">
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      className="auth__form__input"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      minLength="6"
                    />
                    <div className="more-option-wrapper">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={checked}
                            onChange={onCheck}
                          />
                        }
                        label="Remember me"
                      />
                      <Button
                        className="forget-password-button"
                        onClick={onForgetClick}
                      >
                        Forgotten password?
                      </Button>
                    </div>
                    {error ? (
                      <Typography className="error-container error-text">
                        {error}
                        {failedAttempt > 0 && failedAttempt < 5
                          ? ` [ ${5 - failedAttempt} attempts remaining ]`
                          : ""}
                        {accountLocked
                          ? ". Too many failed login attempts. Please try again after 24 hours or reset the password to unlock the account."
                          : ""}
                      </Typography>
                    ) : null}
                    <Button
                      className="auth__form__button"
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      LOGIN
                    </Button>
                  </div>
                )}
              </form>
            </>
          )}
          {/* <p className='auth__authtext'>
                Don't have an account? <Link className='auth__authtext__link' to='/signup'>Sign Up</Link>
            </p> */}
          {/* <p className="l-signuptxt">Can’t Login? &nbsp; <a href="#">Sign up for an Account</a></p> */}
        </Paper>
        <p className="copyright">
          &copy; 2022 Form Leads | All Rights Reserved
        </p>
      </Container>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  clientName: state.auth.clientName,
  error: state.auth.error,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  login,
  resetError,
  submitResetPasswordRequest,
  resendPREmail,
})(Login);
