import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  LinearProgress,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import AddressForm from "../../../components/AddressForm/AddressForm";
import DialogTitle from "../../../components/DialogTitle";
import {
  getAllCountries,
  saveAccountDetails,
} from "../../../redux/modules/settings";

function CreateAccount() {
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.settings.countries);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingC, setLoadingC] = useState(false);

  useEffect(() => {
    if (!countries.length && !loadingC && open) {
      setLoadingC(true);
      dispatch(getAllCountries()).then(() => {
        setLoadingC(false);
      });
    }
  }, [open]);

  const onCreateClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCancelNew = () => {
    setOpen(false);
  };

  const onSaveNew = (account) => {
    return dispatch(saveAccountDetails(account)).then(() => {
      return setOpen(false);
    });
  };

  return (
    <>
      <Button
        className="btn-style"
        variant="contained"
        color="primary"
        onClick={onCreateClick}
      >
        Create Account
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle onClose={handleClose}>Create new Account</DialogTitle>
        <Divider />
        <DialogContent>
          <AddressForm
            cancel={onCancelNew}
            save={onSaveNew}
            countries={countries}
            account
            page
          />
        </DialogContent>
        {isLoading ? <LinearProgress /> : null}
      </Dialog>
    </>
  );
}

export default CreateAccount;
