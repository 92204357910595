import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Button,
  Select,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  InputLabel,
  FormControl,
  MenuItem,
  Paper,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import DialogTitle from "../../components/DialogTitle";
import { Remove, Add } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { createAForm } from "../../redux/modules/forms";
import { Helmet } from "react-helmet";
import GoogleConnect from "../../components/GoogleConnect";
import { validateEmail } from "../../utils/validators";
import config from "../../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EmailInput from "./EmailInput";

const reorder = (list, startIndex, endIndex) => {
  var result = Array.from(list);
  var removed = result.splice(startIndex, 1)[0];
  result.splice(endIndex, 0, removed);
  return result;
};

function FileUploadModal({ open, onSave, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle onClose={onClose}>File upload settings</DialogTitle>
      <Divider />
      <DialogContent>
        <div className="each-file-settings">
          <Typography>Number of files</Typography>
          <Select value={1}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </div>
        <div className="each-file-settings">
          <Typography>Maximum file size</Typography>
          <Select value={1}>
            <MenuItem value={1}>1MB</MenuItem>
            <MenuItem value={10}>10MB</MenuItem>
            <MenuItem value={100}>100MB</MenuItem>
          </Select>
        </div>

        <div className="each-file-settings">
          <Typography>File types</Typography>
          <div>
            <Checkbox />
            <label>All files</label>
            <Checkbox />
            <label>Images</label>
            <Checkbox />
            <label>PDF</label>
            <Checkbox />
            <label>Media</label>
          </div>
        </div>
        <div className="each-file-settings">
          <Typography>Connect to Google Drive</Typography>
          <GoogleConnect />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onSave}>
          Save settings
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CreateForm(props) {
  const navigate = useNavigate();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      label: "Name",
      field_name: "name",
      type: "text",
      placeholder: "Name",
      is_required: "true",
      options: [""],
      editable: "0",
      filter: "0",
      common: "1",
      lead_timeline: "0",
      order: "1",
    },
    {
      id: uuidv4(),
      label: "Email",
      field_name: "email",
      type: "email",
      placeholder: "Email",
      is_required: "true",
      options: [""],
      editable: "0",
      filter: "0",
      common: "1",
      lead_timeline: "0",
      order: "2",
    },
    {
      id: uuidv4(),
      label: "Phone",
      field_name: "phone",
      type: "text",
      placeholder: "Phone",
      is_required: "true",
      options: [""],
      editable: "0",
      filter: "0",
      common: "1",
      lead_timeline: "0",
      order: "3",
    },
    {
      id: uuidv4(),
      label: "",
      type: "",
      placeholder: "",
      is_required: "false",
      options: [""],
      editable: "0",
      filter: "0",
      common: "0",
      lead_timeline: "0",
      order: "4",
    },
  ]);

  /*Formfileds */
  const [formfields, setformfields] = useState({
    title: "",
    description: "",
    description: "",
    category: "",
    fields: [],
    email_subject: "",
    email_to: [],
    email_cc: [],
    success_message: "",
    auto_reply: "",
    recaptcha: "",
  });

  const [open, setOpen] = React.useState(false);
  const [selectedField, setSelectedField] = useState();
  const [currentOptions, setCurrentOptions] = useState([""]);
  const [openFileSettings, setOFS] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleFUClose = () => {
    setOFS(false);
  };

  const formChange = (event, newValue, name) => {
    event?.persist();

    if (name == "email_cc" || name == "email_to") {
      setformfields(() => ({ ...formfields, [name]: newValue }));
    } else if (event.target.name == "recaptcha") {
      setformfields(() => ({
        ...formfields,
        [event.target.name]: event.target.checked ? 1 : 0,
      }));
    } else {
      setformfields(() => ({
        ...formfields,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const addForm = (event) => {
    event.preventDefault();
    formfields.fields = inputFields;
    const fieldItems = Object.keys(formfields);
    fieldItems.forEach((field) => {
      const getValue = formfields[field];
      if (typeof getValue === "string") {
        formfields[field] = getValue.trim();
      }
    });
    props
      .createAForm(formfields)
      .then(() => {
        navigate(`/forms`);
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const addField = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const promptEditOption = (index, event) => {
    inputFields.map((i, ind) => {
      if (ind === index) {
        if (i.options) {
          setCurrentOptions(i.options);
        } else {
          setCurrentOptions([""]);
        }
        setSelectedField(index);
        setOpen(true);
      }
    });
  };

  const onFileSettingsClick = (index, event) => {
    setOFS(true);
  };

  const onSaveFileSettings = () => {
    setOFS(false);
  };

  const handleChangeInput = (index, event, check) => {
    const newInputFields = inputFields.map((i, ind) => {
      const item = i;

      if (item.errorFieldId === index) {
        if (
          item.label.trim().toLowerCase() !=
          event.target.value.trim().toLowerCase()
        ) {
          item.error = "";
          item.errorFieldId = -1;
        }
      }

      if (ind === index) {
        if (event.target.name === "label") {
          if (
            item.common === "0" &&
            config.commonFields.includes(
              event.target.value.trim().toLowerCase()
            )
          ) {
            item.error = "You cannot use this label.";
          } else if (
            item.common === "0" &&
            inputFields.filter(
              (inF, k) =>
                k != ind &&
                !inF.error &&
                inF.label.trim().toLowerCase() ===
                  event.target.value.trim().toLowerCase()
            ).length
          ) {
            item.errorFieldId = inputFields.findIndex(
              (inF, k) =>
                k != ind &&
                !inF.error &&
                inF.label.trim().toLowerCase() ===
                  event.target.value.trim().toLowerCase()
            );
            item.error = "Field with same name already exist.";
          } else {
            item.errorFieldId = -1;
            item.error = "";
          }
        }

        item[event.target.name] = check
          ? event.target.checked
            ? "1"
            : "0"
          : event.target.value;

        if (event.target.value == "radio" || event.target.value == "checkbox") {
          item["editable"] = "0";
        }

        if (
          !check &&
          event.target.name == "type" &&
          !["text", "select"].includes(event.target.value)
        ) {
          item["filter"] = "0";
        }

        if (
          event.target.name == "type" &&
          ["select", "radio", "checkbox"].includes(event.target.value)
        ) {
          item.options = [""];
          setCurrentOptions([""]);
          setSelectedField(index);
          setOpen(true);
        }
      }
      return item;
    });

    setInputFields(newInputFields);
  };

  const handleOptionChange = (index, event) => {
    const updatedArray = [...currentOptions];
    updatedArray[index] = event.target.value;
    setCurrentOptions(updatedArray);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        label: "",
        type: "",
        placeholder: "",
        is_required: "false",
        options: [""],
        editable: "0",
        filter: "0",
        common: "0",
        lead_timeline: "0",
        order: inputFields.length + 1,
      },
    ]);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values.map((field, i) => ({ ...field, order: `${i + 1}` })));
  };

  const handleAddOptions = () => {
    setCurrentOptions([...currentOptions, ""]);
  };

  const handleRemoveOptions = (option) => {
    const values = [...currentOptions];
    values.splice(
      values.findIndex((value) => value === option),
      1
    );
    setCurrentOptions(values);
  };

  const saveOptions = () => {
    const newInputFields = inputFields.map((i, index) => {
      if (selectedField === index) {
        i["options"] = currentOptions.map((co) => co.trim());
      }

      return i;
    });

    setInputFields(newInputFields);
    setCurrentOptions([""]);
    setOpen(false);
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(inputFields, source.index, destination.index);

    // Create a new array here!
    const newItemOrdered = newItems.map(
      (el, index) => (el[index] = { ...el, order: index + 1 })
    );

    setInputFields(newItemOrdered);
  };

  const totalFilterFields = inputFields.filter(
    (inFields) => inFields.filter == "1"
  ).length;
  const optionsIncomplete = !!inputFields.filter(
    (inFi) =>
      ["select", "radio", "checkbox"].includes(inFi.type) &&
      inFi.options.filter((o) => !o.trim()).length
  ).length;
  const errorExist = !!inputFields.filter((inFi) => inFi.error).length;

  return (
    <React.Fragment>
      <Helmet>
        <title>Create Forms</title>
        <meta name="description" content="Create Form page" />
      </Helmet>

      <Typography
        className="form_heading"
        variant="h5"
        gutterBottom
        align="center"
      >
        CREATE NEW FORM
      </Typography>

      <Container margin="10" maxWidth="md">
        <form onSubmit={addForm} className="Addform">
          <Paper elevation={2} className="Addform">
            <Typography variant="h6" gutterBottom align="left">
              Add Form details
            </Typography>
            <Grid container spacing={4}>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  size="small"
                  onChange={formChange}
                  required
                  id="title"
                  name="title"
                  label="Title"
                  fullWidth
                  autoComplete="off"
                  value={formfields.title}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  required
                  id="category"
                  name="category"
                  label="Category"
                  fullWidth
                  autoComplete="category"
                  value={formfields.category}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="description"
                  name="description"
                  label="Description"
                  fullWidth
                  autoComplete="description"
                  value={formfields.description}
                  variant="standard"
                />
              </Grid>

              <Grid className="grid_pad_x" xs={12}>
                <EmailInput
                  formfields={formfields}
                  formChange={formChange}
                  validateEmail={validateEmail}
                  fieldName={"email_to"}
                  label={"Email to"}
                  valueField={formfields.email_to}
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <EmailInput
                  formfields={formfields}
                  formChange={formChange}
                  validateEmail={validateEmail}
                  fieldName={"email_cc"}
                  label={"Email cc"}
                  valueField={formfields.email_cc}
                />
              </Grid>

              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="email_subject"
                  name="email_subject"
                  label="Email Subject"
                  fullWidth
                  autoComplete="email_subject"
                  value={formfields.email_subject}
                  variant="standard"
                />
              </Grid>
              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="success_message"
                  name="success_message"
                  label="Success Message"
                  fullWidth
                  autoComplete="success_message"
                  value={formfields.success_message}
                  variant="standard"
                />
              </Grid>

              <Grid className="grid_pad_x" xs={12}>
                <TextField
                  onChange={formChange}
                  id="auto_reply"
                  name="auto_reply"
                  label="Auto Reply"
                  fullWidth
                  autoComplete="success_message"
                  value={formfields.auto_reply}
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formfields.recaptcha == "1"}
                      onChange={formChange}
                      name="recaptcha"
                    />
                  }
                  label="Enable Recaptcha"
                />
              </Grid>
            </Grid>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-list">
                {(provided) => (
                  <div
                    className="addFormFeild"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Typography
                      className="form_sec_head sub-head"
                      variant="h6"
                      align="left"
                    >
                      Add Form Fields
                    </Typography>
                    {inputFields.map((inputField, index) => {
                      const commonFields = inputField.common == "1";

                      return (
                        <Draggable
                          draggableId={`${index + 1}`}
                          index={index}
                          key={index + 1}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={index + 1}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`form_fields ${
                                commonFields ? "common_fields" : ""
                              } ${
                                snapshot.isDragging ? "dragging_list_item" : ""
                              }`}
                            >
                              <div className="field_editor">
                                <div className="field_attr_wrapper">
                                  <TextField
                                    className="text-field"
                                    size="small"
                                    required
                                    name="label"
                                    label="Label"
                                    variant="outlined"
                                    value={inputField.label}
                                    error={!!inputField.error}
                                    helperText={
                                      inputField.error ||
                                      inputField.field_name ||
                                      ""
                                    }
                                    // className={classes.textField}
                                    onChange={(event) =>
                                      handleChangeInput(index, event)
                                    }
                                  />

                                  <FormControl
                                    variant="outlined"
                                    className="select-field"
                                    disabled={commonFields}
                                  >
                                    <InputLabel id="field_type">
                                      Type *
                                    </InputLabel>
                                    <Select
                                      size="small"
                                      required
                                      label="Type"
                                      name="type"
                                      value={inputField.type}
                                      onChange={(event) =>
                                        handleChangeInput(index, event)
                                      }
                                      fullWidth
                                    >
                                      <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      <MenuItem value={"text"}>Text</MenuItem>
                                      <MenuItem value={"email"}>Email</MenuItem>
                                      <MenuItem value={"number"}>
                                        Number
                                      </MenuItem>
                                      <MenuItem value={"checkbox"}>
                                        Checkbox
                                      </MenuItem>
                                      <MenuItem value={"radio"}>Radio</MenuItem>
                                      <MenuItem value={"select"}>
                                        Select
                                      </MenuItem>
                                      {/* <MenuItem value={"file"}>File upload</MenuItem> */}
                                    </Select>
                                    {["select", "radio", "checkbox"].includes(
                                      inputFields[index].type
                                    ) ? (
                                      <Button
                                        variant="outlined"
                                        className={`add-option-btn ${
                                          inputFields[index].options[0]
                                            ? "bdr-btn-style-white"
                                            : "bdr-btn-style-chu"
                                        }`}
                                        onClick={(event) =>
                                          promptEditOption(index, event)
                                        }
                                      >
                                        {inputFields[index].options[0]
                                          ? "Edit options"
                                          : "Add some options"}
                                      </Button>
                                    ) : null}
                                    {["file"].includes(
                                      inputFields[index].type
                                    ) ? (
                                      <Button
                                        variant="outlined"
                                        className={`add-option-btn bdr-btn-style-white`}
                                        onClick={(e) =>
                                          onFileSettingsClick(index, e)
                                        }
                                      >
                                        file upload settings
                                      </Button>
                                    ) : null}
                                  </FormControl>

                                  <TextField
                                    className="text-field"
                                    size="small"
                                    required
                                    name="placeholder"
                                    label="Placeholder"
                                    variant="outlined"
                                    value={inputField.placeholder}
                                    onChange={(event) =>
                                      handleChangeInput(index, event)
                                    }
                                  />

                                  <FormControl
                                    variant="outlined"
                                    className="select-field"
                                  >
                                    <InputLabel>Is Required</InputLabel>
                                    <Select
                                      size="small"
                                      name="is_required"
                                      label="isrequired"
                                      value={inputField.is_required}
                                      onChange={(event) =>
                                        handleChangeInput(index, event)
                                      }
                                    >
                                      <MenuItem value={"true"}>True</MenuItem>
                                      <MenuItem value={"false"}>False</MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                                <div className="editNAddWrapper">
                                  <FormControlLabel
                                    className="add_as_filt_check"
                                    control={
                                      <Checkbox
                                        checked={inputField.filter === "1"}
                                        name="filter"
                                        onChange={(event) =>
                                          handleChangeInput(
                                            index,
                                            event,
                                            "checkbox"
                                          )
                                        }
                                        disabled={
                                          (inputField.filter != "1" &&
                                            totalFilterFields === 3) ||
                                          !["text", "select"].includes(
                                            inputField.type
                                          )
                                        }
                                      />
                                    }
                                    label="Add as Filter"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          inputField.lead_timeline == "1"
                                        }
                                        name="lead_timeline"
                                        onChange={(event) =>
                                          handleChangeInput(
                                            index,
                                            event,
                                            "checkbox"
                                          )
                                        }
                                      />
                                    }
                                    label="Show in timeline"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={inputField.editable === "1"}
                                        name="editable"
                                        onChange={(event) =>
                                          handleChangeInput(
                                            index,
                                            event,
                                            "checkbox"
                                          )
                                        }
                                        disabled={[
                                          "checkbox",
                                          "radio",
                                          "file",
                                        ].includes(inputField.type)}
                                      />
                                    }
                                    label="Admin Editable"
                                  />
                                </div>
                              </div>
                              <div className="form_add_del_wrap">
                                <IconButton
                                  disabled={
                                    commonFields || inputFields.length === 1
                                  }
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  <Remove />
                                </IconButton>
                                {inputFields.length !== index + 1 ? null : (
                                  <IconButton onClick={handleAddFields}>
                                    <Add />
                                  </IconButton>
                                )}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={optionsIncomplete || errorExist}
            >
              Create Form
            </Button>
            {optionsIncomplete ? (
              <Typography
                component="div"
                className="form_submit__error error-container"
                color="error"
              >
                Update options to submit
              </Typography>
            ) : null}
            <div>
              <Typography component="div" className="form_submit__info">
                * mandatory inputs
              </Typography>
              <Typography component="div" className="form_submit__info">
                <span className="man_field_clr"></span> Mandatory fields
              </Typography>
            </div>
          </Paper>
        </form>
      </Container>

      <div>
        <FileUploadModal
          open={openFileSettings}
          onSave={onSaveFileSettings}
          onClose={handleFUClose}
        />
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Add Field Properties
          </DialogTitle>
          <DialogContent dividers>
            {currentOptions.map((option, index) => {
              return (
                <div key={index}>
                  <TextField
                    className="text-field"
                    required
                    name="item"
                    label="Option"
                    variant="outlined"
                    onChange={(event) => handleOptionChange(index, event)}
                    value={option}
                    size="small"
                  />
                  <IconButton
                    disabled={currentOptions.length === 1}
                    onClick={() => handleRemoveOptions(option)}
                  >
                    <Remove />
                  </IconButton>

                  <IconButton onClick={handleAddOptions}>
                    <Add />
                  </IconButton>
                </div>
              );
            })}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={saveOptions}
              color="primary"
              disabled={currentOptions.filter((o) => !o.trim()).length}
            >
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}

export default connect((state) => ({}), { createAForm })(CreateForm);
