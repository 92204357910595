import { setAlert } from './alert';

// ACTION TYPES
const GET_PRIORITY_LIST = 'GET_PRIORITY_LIST';
const GET_PRIORITY_LIST_SUCCESS = 'GET_PRIORITY_LIST_SUCCESS';
const GET_PRIORITY_LIST_FAILED = 'GET_PRIORITY_LIST_FAILED';

const GET_STATUS_LIST = 'GET_STATUS_LIST';
const GET_STATUS_LIST_SUCCESS = 'GET_STATUS_LIST_SUCCESS';
const GET_STATUS_LIST_FAILED = 'GET_STATUS_LIST_FAILED';

const GET_PRIORITY_TIME = 'GET_PRIORITY_TIME';
const GET_PRIORITY_TIME_SUCCESS = 'GET_PRIORITY_TIME_SUCCESS';
const GET_PRIORITY_TIME_FAILED = 'GET_PRIORITY_TIME_FAILED';

const UPDATE_PRIORITY_LIST = 'UPDATE_PRIORITY_LIST';
const UPDATE_PRIORITY_LIST_SUCCESS = 'UPDATE_PRIORITY_LIST_SUCCESS';
const UPDATE_PRIORITY_LIST_FAILED = 'UPDATE_PRIORITY_LIST_FAILED';

const UPDATE_STATUS_LIST = 'UPDATE_STATUS_LIST';
const UPDATE_STATUS_LIST_SUCCESS = 'UPDATE_STATUS_LIST_SUCCESS';
const UPDATE_STATUS_LIST_FAILED = 'UPDATE_STATUS_LIST_FAILED';

const GET_PRIORITY_MAPPINGS = 'GET_PRIORITY_MAPPINGS';
const GET_PRIORITY_MAPPINGS_SUCCESS = 'GET_PRIORITY_MAPPINGS_SUCCESS';
const GET_PRIORITY_MAPPINGS_FAILED = 'GET_PRIORITY_MAPPINGS_FAILED';

const GET_ROLES_PERMISSIONS = 'GET_ROLES_PERMISSIONS';
const GET_ROLES_PERMISSIONS_SUCCESS = 'GET_ROLES_PERMISSIONS_SUCCESS';
const GET_ROLES_PERMISSIONS_FAILED = 'GET_ROLES_PERMISSIONS_FAILED';

const GET_ROLES = 'GET_ROLES';
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

const ADD_ROLE = 'ADD_ROLE';
const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
const ADD_ROLE_FAILED = 'ADD_ROLE_FAILED';

const UPDATE_ROLE = 'UPDATE_ROLE';
const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
const UPDATE_ROLE_FAILED = 'UPDATE_ROLE_FAILED';

const DELETE_ROLE = 'DELETE_ROLE';
const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
const DELETE_ROLE_FAILED = 'DELETE_ROLE_FAILED';

const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
const UPDATE_PERMISSIONS_SUCCESS = 'UPDATE_PERMISSIONS_SUCCESS';
const UPDATE_PERMISSIONS_FAILED = 'UPDATE_PERMISSIONS_FAILED';

const GET_KEYS = 'GET_KEYS';
const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS';
const GET_KEYS_FAILED = 'GET_KEYS_FAILED';

const GET_NOTI_SWITCHES = 'GET_NOTI_SWITCHES';
const GET_NOTI_SWITCHES_SUCCESS = 'GET_NOTI_SWITCHES_SUCCESS';
const GET_NOTI_SWITCHES_FAILED = 'GET_NOTI_SWITCHES_FAILED';

const POST_NOTI_SWITCHES = 'POST_NOTI_SWITCHES';
const POST_NOTI_SWITCHES_SUCCESS = 'POST_NOTI_SWITCHES_SUCCESS';
const POST_NOTI_SWITCHES_FAILED = 'POST_NOTI_SWITCHES_FAILED';

const GET_ALL_ADETAILS = 'GET_ALL_ADETAILS';
const GET_ALL_ADETAILS_SUCCESS = 'GET_ALL_ADETAILS_SUCCESS';
const GET_ALL_ADETAILS_FAILED = 'GET_ALL_ADETAILS_FAILED';

const SAVE_ACC_DETAILS = 'SAVE_ACC_DETAILS';
const SAVE_ACC_DETAILS_SUCCESS = 'SAVE_ACC_DETAILS_SUCCESS';
const SAVE_ACC_DETAILS_FAILED = 'SAVE_ACC_DETAILS_FAILED';

const UPDATE_ACC_DETAILS = 'SAVE_ACC_DETAILS';
const UPDATE_ACC_DETAILS_SUCCESS = 'SAVE_ACC_DETAILS_SUCCESS';
const UPDATE_ACC_DETAILS_FAILED = 'SAVE_ACC_DETAILS_FAILED';

const GET_COUNTRIES = 'GET_COUNTRIES';
const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
const GET_COUNTRIES_FAILED = 'GET_COUNTRIES_FAILED';

const GET_APP_KEY = 'GET_APP_KEY';
const GENERATE_APP_KEY = 'GENERATE_APP_KEY';
const APP_KEY_DOMAIN_CREATION = 'APP_KEY_DOMAIN_CREATION';
const DELETE_APP_KEY_DOMAIN = 'DELETE_APP_KEY_DOMAIN';
const BYPASS_APP_KEY = 'BYPASS_APP_KEY'

// INITIAL
const initialState = {
    priorityList: [],
    statusList: [],
    priorityTimes: [],
    priorityMappings: [],
    timeUnits: {
        "1": "secs",
        "2": "mins",
        "3": "hrs",
        "4": "days",
        "5": "weeks",
        "6": "months",
        "7": "yrs"
    },
    loading: false,
    roles: [],
    rolePermissions: [],
    keys: null,
    loadingRoles: false,
    notification: "1",
    detailedAccounts: [],
    countries: [],
    appKey: []
};

// REDUCER
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case UPDATE_PERMISSIONS:
        case ADD_ROLE:
        case UPDATE_ROLE:
        case DELETE_ROLE:
        case GET_PRIORITY_LIST:
            return {
                ...state,
                loading: true
            };
        case GET_PRIORITY_LIST_SUCCESS:
            return {
                ...state,
                priorityList: payload.result || [],
                loading: false
            };
        case GET_ROLES:
            return {
                ...state,
                loadingRoles: true
            };
        case GET_STATUS_LIST:
            return {
                ...state,
                loading: true
            };
        case GET_STATUS_LIST_SUCCESS:
            return {
                ...state,
                statusList: payload.result || [],
                loading: false
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: payload.result || [],
                loadingRoles: false
            };
        case GET_ROLES_PERMISSIONS:
        case UPDATE_STATUS_LIST:
        case GET_PRIORITY_TIME:
            return {
                ...state,
                loading: true
            };
        case GET_PRIORITY_TIME_SUCCESS:
            return {
                ...state,
                priorityTimes: payload.result || [],
                loading: false
            };
        case GET_PRIORITY_MAPPINGS:
            return {
                ...state,
                loading: true
            };
        case GET_PRIORITY_MAPPINGS_SUCCESS:
            return {
                ...state,
                priorityMappings: payload,
                loading: false
            };
        case GET_ROLES_PERMISSIONS_SUCCESS:
            return {
                ...state,
                rolePermissions: payload.result || [],
                loading: false
            };
        case GET_ROLES_FAILED:
            return {
                ...state,
                loadingRoles: false
            };
        case GET_KEYS_SUCCESS:
            return {
                ...state,
                keys: {...(payload.result || {})}
            };
        case GET_NOTI_SWITCHES_SUCCESS:
            return {
                ...state,
                notification: payload.notification || state.notification
            };
        case GET_ALL_ADETAILS_SUCCESS:
            return {
                ...state,
                detailedAccounts: payload.result || []
            };
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: payload.result || []
            };
        case UPDATE_PERMISSIONS_SUCCESS:
        case UPDATE_PERMISSIONS_FAILED:
        case ADD_ROLE_SUCCESS:
        case ADD_ROLE_FAILED:
        case UPDATE_ROLE_SUCCESS:
        case UPDATE_ROLE_FAILED:
        case DELETE_ROLE_SUCCESS:
        case DELETE_ROLE_FAILED:
        case GET_ROLES_PERMISSIONS_FAILED:
        case GET_PRIORITY_MAPPINGS_FAILED:
        case UPDATE_STATUS_LIST_FAILED:
        case GET_PRIORITY_TIME_FAILED:
        case GET_PRIORITY_LIST_FAILED:
        case GET_PRIORITY_LIST_FAILED:
            return {
                ...state,
                loading: false
            };
        case GET_APP_KEY:
            return {
                ...state,
                appKey: "payload" || []
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export function getPriorityList() {
    return {
        types: [GET_PRIORITY_LIST, GET_PRIORITY_LIST_SUCCESS, GET_PRIORITY_LIST_FAILED],
        promise: fetch => fetch.get("api/prioritylist/")
    };
}

export function getStatusList() {
    return {
        types: [GET_STATUS_LIST, GET_STATUS_LIST_SUCCESS, GET_STATUS_LIST_FAILED],
        promise: fetch => fetch.get("api/leadstatuslist/")
    };
}

export function getPriorityTimes() {
    return {
        types: [GET_PRIORITY_TIME, GET_PRIORITY_TIME_SUCCESS, GET_PRIORITY_TIME_FAILED],
        promise: fetch => fetch.get("api/prioritytime/")
    };
}

export function getPriorityMappings() {
    return {
        types: [GET_PRIORITY_MAPPINGS, GET_PRIORITY_MAPPINGS_SUCCESS, GET_PRIORITY_MAPPINGS_FAILED],
        promise: fetch => fetch.get("api/prioritymap/")
    };
}

export function getRoles() {
    return {
        types: [GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILED],
        promise: fetch => fetch.get("api/roles/")
    };
}

export function getRolePermissions() {
    return {
        types: [GET_ROLES_PERMISSIONS, GET_ROLES_PERMISSIONS_SUCCESS, GET_ROLES_PERMISSIONS_FAILED],
        promise: fetch => fetch.get("api/Access")
    };
}

export function updatePriorityList(fields) {
    return dispatch => dispatch({
        types: [UPDATE_PRIORITY_LIST, UPDATE_PRIORITY_LIST_SUCCESS, UPDATE_PRIORITY_LIST_FAILED],
        promise: fetch => fetch.put("api/prioritylist/", { 
            data: fields
         })
    })
    .then(() => {
        dispatch(setAlert("Priorities updated successfully.", "success"));

        return dispatch(getPriorityList());
    });
}

export function updateStatusList(fields) {
    return dispatch => dispatch({
        types: [UPDATE_STATUS_LIST, UPDATE_STATUS_LIST_SUCCESS, UPDATE_STATUS_LIST_FAILED],
        promise: fetch => fetch.put("api/leadstatuslist/", { 
            data: fields
         })
    })
    .then(() => {
        dispatch(setAlert("Statuses updated successfully.", "success"));

        return dispatch(getStatusList());
    });
}

export function updateTimeList(fields) {
    return dispatch => dispatch({
        types: [UPDATE_STATUS_LIST, UPDATE_STATUS_LIST_SUCCESS, UPDATE_STATUS_LIST_FAILED],
        promise: fetch => fetch.put("api/prioritytime/", { 
            data: fields
         })
    })
    .then(() => {
        dispatch(setAlert("Times updated successfully.", "success"));

        return dispatch(getPriorityTimes());
    });
}

export function updatePriorityMappings(fields) {
    return dispatch => dispatch({
        types: [UPDATE_STATUS_LIST, UPDATE_STATUS_LIST_SUCCESS, UPDATE_STATUS_LIST_FAILED],
        promise: fetch => fetch.put("api/prioritymap/", { 
            data: fields
         })
    })
    .then(() => {
        dispatch(setAlert("Priority Mappings updated successfully.", "success"));

        return dispatch(getPriorityMappings());
    });
}

export function updateRolesPermissions(data) {
    return dispatch => dispatch({
        types: [UPDATE_PERMISSIONS, UPDATE_PERMISSIONS_SUCCESS, UPDATE_PERMISSIONS_FAILED],
        promise: fetch => fetch.put('/api/Access', { data })
    }).then(() => {
        dispatch(setAlert("Permisions updated successfully.", "success"));
    });
}

export function addNewRole(role) {
    return dispatch => dispatch({
        types: [ADD_ROLE, ADD_ROLE_SUCCESS, ADD_ROLE_FAILED],
        promise: fetch => fetch.post('/api/roles', {
            data: {
                name: role.name
            }
        }).then((res) => {
            if (res.status === 1) {
                dispatch(setAlert("New user role added successfully.", "success"));
    
                return dispatch(getRoles());
            } else if (res.status === 0) {
                dispatch(setAlert(`[Error] ${res.message}`, "error"));
    
                return Promise.reject();
            }
        })
    });
}

export function updateRole(role) {
    return dispatch => dispatch({
        types: [UPDATE_ROLE, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILED],
        promise: fetch => fetch.put('/api/roles', {
            data: role
        }).then((res) => {
            if (res.status === 1) {
                dispatch(setAlert("User role updated successfully.", "success"));

                return dispatch(getRoles());
            } else if (res.status === 0) {
                dispatch(setAlert(`[Error] ${res.message}`, "error"));

                return Promise.reject();
            }
        })
    });
}

export function deleteRole(role_id) {
    return dispatch => dispatch({
        types: [DELETE_ROLE, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILED],
        promise: fetch => fetch.delete('/api/roles', {
            data: {
                role_id
            }
        }).then(() => {
            dispatch(setAlert("User role deleted successfully.", "success"));

            return dispatch(getRoles());
        })
    });
}

export function getKeys() {
    return {
        types: [GET_KEYS, GET_KEYS_SUCCESS, GET_KEYS_FAILED],
        promise: fetch => fetch.get('api/dkimkey')
    };
}

export function getNotiSwitches() {
    return {
        types: [GET_NOTI_SWITCHES, GET_NOTI_SWITCHES_SUCCESS, GET_NOTI_SWITCHES_FAILED],
        promise: fetch => fetch.get('/api/Notification')
    };
}

export function postNotiSwitches(notification) {
    return dispatch => dispatch({
        types: [POST_NOTI_SWITCHES, POST_NOTI_SWITCHES_SUCCESS, POST_NOTI_SWITCHES_FAILED],
        promise: fetch => fetch.post('api/Notification', {
            data: {
                notification
            }
        })
    }).then(() => dispatch(getNotiSwitches()));
}

export function getAllAccountDetails() {
    return {
        types: [GET_ALL_ADETAILS, GET_ALL_ADETAILS_SUCCESS, GET_ALL_ADETAILS_FAILED],
        promise: fetch => fetch.get('api/Accountdetails')
    };
}

export function saveAccountDetails(account) {
    return dispatch => dispatch({
        types: [SAVE_ACC_DETAILS, SAVE_ACC_DETAILS_SUCCESS, SAVE_ACC_DETAILS_FAILED],
        promise: fetch => fetch.post('api/Accountdetails', {
            data: account
        })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Account (${account.name}) created successfully.`, "success"));
      
            return dispatch(getAllAccountDetails());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));
    
            return Promise.reject();
        }
    });
}

export function updateAccountDetails(account) {
    return dispatch => dispatch({
        types: [UPDATE_ACC_DETAILS, UPDATE_ACC_DETAILS_SUCCESS, UPDATE_ACC_DETAILS_FAILED],
        promise: fetch => fetch.put('api/Accountdetails', {
            data: account
        })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Account (${account.name}) updated successfully.`, "success"));
      
            return dispatch(getAllAccountDetails());
        } else if (res.status === 0) {
            dispatch(setAlert(`[Error] ${res.message}`, "error"));
      
            return Promise.reject();
          }
    });
}

export function getAllCountries() {
    return {
        types: [GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILED],
        promise: fetch => fetch.get('api/Countries')
    };
}

export function getAppKey() {
    return {
        types: GET_APP_KEY,
        promise: fetch => fetch.get('api/Appkey_data')
    }
}

export function generateAppKey() {
    return {
        types: GENERATE_APP_KEY,
        promise: fetch => fetch.post('api/Appkey_data')
    }
}

export function appKeyDomainCreation(domain) {
    return {
        types: APP_KEY_DOMAIN_CREATION,
        promise: fetch => fetch.post('api/Appkey_domain', {
            data: domain,
        })
    }
}

export function deleteAppKeyDomain(domain) {
    return {
        types: DELETE_APP_KEY_DOMAIN,
        promise: fetch => fetch.delete('api/Appkey_domain', {
            data: domain
        })
    }
}

export function bypassAppKey(value) {
    return {
        types: BYPASS_APP_KEY,
        promise: fetch => fetch.post('api/By_pass_appkey', {
            data: value
        })
    }
}