import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Button,
  Divider,
  Collapse,
  TextField,
  Typography,
  LinearProgress,
  Chip,
  Avatar,
  IconButton,
  Paper,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDealsList,
  setSelectedDeal,
  getDealPipelines,
  getPipeAndSetPipe,
  setSelectedDealStage,
  setSelectedCurrency,
  getCurrencies,
  postADeal,
  setSelectedOwner,
  getContactsWithAccount,
  updateADeal,
  getFirstSetOfDL,
} from "../../redux/modules/deals";
import {
  setSelectedAccount,
  getAllAccountAndSetFirst,
} from "../../redux/modules/leads";
import {
  AddCircleOutline,
  HighlightOff as HighlightOffIcon,
  LocalOffer as LocalOfferIcon,
  Close as CloseIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import AddToContact from "../../containers/Leads/components/AddToContact";

function isContactsEqual(oldC, newC) {
  return (
    oldC.length == newC.length &&
    !oldC.filter((oc, i) => oc.owner != newC[i].owner).length
  );
}

function RelatedContacts(props) {
  const [open, setOpen] = useState(false);
  const availableContacts = props.contacts.filter(
    (con) => !props.relatedC.find((rcon) => rcon.contact_id === con.contact_id)
  );

  const onAddContactToList = (cc) => {
    const oldRC = [...props.relatedC];
    oldRC.push({ ...cc, owner: "0" });

    props.setRCL(oldRC);
  };

  const handleDelete = (c) => {
    const oldRC = [...props.relatedC];
    oldRC.splice(c, 1);

    props.setRCL(oldRC);
  };

  const onNewCBtnClick = () => {
    props.addNewC();
  };

  return (
    <div>
      <div className="rc-tool">
        <div className="related-contacts-edit-wrapper">
          {props.relatedC.map((cont, c) => (
            <Chip
              key={c}
              variant="outlined"
              color="primary"
              size="small"
              label={cont.name}
              avatar={
                <Avatar className="contact-avatar">
                  {cont.name.slice(0, 1)}
                </Avatar>
              }
              clickable
              onDelete={() => handleDelete(c)}
              deleteIcon={<CloseIcon />}
              className="related-contact-chips"
            />
          ))}
        </div>
        <div className="related_contacts_btn_wrapper">
          <Tooltip title="Tag contacts">
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <HighlightOffIcon /> : <LocalOfferIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Add new contact">
            <IconButton onClick={onNewCBtnClick}>
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Collapse in={open}>
        <Paper className="contacts-list-paper">
          {availableContacts.length ? (
            availableContacts.map((contact, cc) => (
              <div key={cc}>
                <IconButton onClick={() => onAddContactToList(contact)}>
                  <AddCircleOutline />
                </IconButton>
                {contact.name}
              </div>
            ))
          ) : (
            <Typography>No contacts available...</Typography>
          )}
        </Paper>
      </Collapse>
    </div>
  );
}

function DealForm(props) {
  const dispatch = useDispatch();
  const editableDeal = props.deal || {};
  const dealsList = useSelector((state) => state.deals.dealsList);
  const selectedDeal = useSelector((state) => state.deals.selectedDeal);
  const selectedPipeline = useSelector((state) => state.deals.selectedPipeline);
  const selectedDealStage = useSelector(
    (state) => state.deals.selectedDealStage
  );
  const selectedCurrency = useSelector((state) => state.deals.selectedCurrency);
  const selectedOwner = useSelector((state) => state.deals.selectedOwner);
  const selectedAccount = useSelector((state) => state.leads.selectedAccount);
  const accountedContacts = useSelector(
    (state) => state.deals.accountedContacts
  );
  const currencies = useSelector((state) => state.deals.currencies);
  const pipeDetails = useSelector((state) => state.deals.pipe);
  const pipelines = useSelector((state) => state.deals.pipelines);
  const [newDeal, setNew] = useState(props.edit || props.create);
  const [loading, setLoading] = useState(false);
  const [pipeLoad, setPipeLoad] = useState(false);
  const [dealName, setDealName] = useState(editableDeal.deal_name || "");
  const [dealValue, setDealValue] = useState(editableDeal.deal_value || 0);
  const [relatedContactList, setRelatedContactsList] = useState(
    editableDeal.tagged_contacts || []
  );
  const [tagNewC, setTagNewC] = useState(false);
  const accounts = useSelector((state) => state.leads.accounts);

  useEffect(() => {
    if (editableDeal.tagged_contacts) {
      setRelatedContactsList(editableDeal.tagged_contacts);
    }
  }, [editableDeal]);

  useEffect(() => {
    if (props.create && !accounts.length) {
      dispatch(getAllAccountAndSetFirst());
    }

    if (!dealsList.length && !props.edit && !props.create) {
      setLoading(true);
      dispatch(getDealsList()).then(() => {
        setLoading(false);
      });
    }

    if (props.edit || props.create) {
      if (!currencies.length) {
        dispatch(getCurrencies()).then(() => {
          dispatch(
            setSelectedCurrency(
              props.create ? "USD" : editableDeal.deal_value_currency
            )
          );
        });
      } else {
        dispatch(
          setSelectedCurrency(
            props.create ? "USD" : editableDeal.deal_value_currency
          )
        );
      }

      if (!props.create) {
        dispatch(getContactsWithAccount(editableDeal.contact_account));
      }

      if (editableDeal.tagged_contacts) {
        const ownerContact = editableDeal.tagged_contacts.find(
          (tc) => tc.owner === "1"
        );

        dispatch(
          setSelectedOwner((ownerContact && ownerContact.contact_id) || "")
        );
      }

      if (!pipelines.length) {
        setPipeLoad(true);
        dispatch(getDealPipelines())
          .then(() => {
            setPipeLoad(false);
            dispatch(getPipeAndSetPipe(editableDeal.deal_pipeline));
            dispatch(setSelectedDealStage(editableDeal.deal_stage));
          })
          .catch(() => {
            setPipeLoad(false);
          });
      } else {
        dispatch(getPipeAndSetPipe(editableDeal.deal_pipeline));
        dispatch(setSelectedDealStage(editableDeal.deal_stage));
      }
    }
  }, []);

  const onAddNewDeal = () => {
    setNew(true);
    dispatch(setSelectedDeal(""));

    if (!currencies.length) {
      dispatch(getCurrencies());
    }

    if (!pipelines.length) {
      setPipeLoad(true);
      dispatch(getDealPipelines())
        .then(() => {
          setPipeLoad(false);
        })
        .catch(() => {
          setPipeLoad(false);
        });
    }

    if (!props.edit) {
      setDealName("");
      setDealValue(0);
      selectedPipeline && dispatch(getPipeAndSetPipe(""));
      selectedDealStage && dispatch(setSelectedDealStage(""));
      selectedCurrency && dispatch(setSelectedCurrency("USD"));
    }
  };

  const onDealSelect = (e) => {
    const value = e.target.value === "select" ? "" : e.target.value;

    dispatch(setSelectedDeal(value));
  };

  const onDealNameChange = (e) => {
    setDealName(e.target.value);
  };

  const onDealValueChange = (e) => {
    setDealValue(e.target.value);
  };

  const onPipelineSelect = (e) => {
    const value = e.target.value === "select" ? "" : e.target.value;

    dispatch(getPipeAndSetPipe(value));
  };

  const onDealStageSelect = (e) => {
    const value = e.target.value === "select" ? "" : e.target.value;

    dispatch(setSelectedDealStage(value));
  };

  const onCurrencySelect = (e) => {
    const value = e.target.value === "select" ? "" : e.target.value;

    dispatch(setSelectedCurrency(value));
  };

  const onAccountChange = (e) => {
    const value = e.target.value === "select" ? "" : e.target.value;

    dispatch(setSelectedAccount(value));
  };

  const onDealCancel = () => {
    if (props.cancel) {
      props.cancel();
    } else {
      setNew(false);
      setDealName("");
      setDealValue("0");
      selectedPipeline && dispatch(getPipeAndSetPipe(""));
      selectedDealStage && dispatch(setSelectedDealStage(""));
      selectedCurrency && dispatch(setSelectedCurrency("USD"));
    }
  };

  const onDealSave = () => {
    const changed = {
      ...(editableDeal.deal_name != dealName ? { deal_name: dealName } : {}),
      ...(editableDeal.deal_value != dealValue
        ? { deal_value: dealValue }
        : {}),
      ...(editableDeal.deal_value_currency != selectedCurrency
        ? { deal_value_currency: selectedCurrency }
        : {}),
      ...(editableDeal.deal_pipeline != selectedPipeline
        ? { deal_pipeline: selectedPipeline }
        : {}),
      ...(editableDeal.deal_stage != selectedDealStage
        ? { deal_stage: selectedDealStage }
        : {}),
      ...(!isContactsEqual(
        editableDeal.tagged_contacts || [],
        relatedContactList
      )
        ? { tagged_contacts: relatedContactList }
        : { tagged_contacts: [] }),
    };

    if (props.edit) {
      setLoading(true);
      dispatch(
        updateADeal({
          deal_id: editableDeal.deal_id,
          ...changed,
        })
      )
        .then(() => {
          dispatch(getFirstSetOfDL(editableDeal.deal_id)).then(() => {
            setLoading(false);
            props.cancel();
          });
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      dispatch(
        postADeal(
          {
            deal_name: dealName,
            deal_value: dealValue,
            deal_value_currency: selectedCurrency,
            deal_pipeline: selectedPipeline,
            deal_stage: selectedDealStage,
            contact_account: selectedAccount,
          },
          props.create
        )
      )
        .then(() => {
          if (props.create) {
            props.cancel();
          }

          setLoading(false);
          setNew(false);
        })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onOwnerChange = (e) => {
    if (e.target.value && e.target.value != "select") {
      const oldRC = relatedContactList.map((rcl) => ({
        ...rcl,
        owner: e.target.value === rcl.contact_id ? 1 : 0,
      }));
      setRelatedContactsList(oldRC);
    }

    dispatch(setSelectedOwner(e.target.value));
  };

  const addNewContactClick = () => {
    setTagNewC(true);
  };

  const cancelAddNewC = () => {
    setTagNewC(false);
  };

  const onCompleteSave = () => {
    setTagNewC(false);
  };

  let allAvailable =
    dealName &&
    dealValue &&
    selectedPipeline &&
    selectedDealStage &&
    selectedCurrency &&
    !loading;

  if (props.create) {
    allAvailable = allAvailable && selectedAccount;
  }

  let finalList = dealsList;

  if (props.accountId) {
    finalList = dealsList.filter((dl) => props.accountId == dl.contact_account);
  }

  return (
    <div className="deals-section">
      {finalList.length && !props.edit && !props.create ? (
        <Select
          margin="dense"
          variant="outlined"
          style={{ minWidth: 200, maxHeight: 40 }}
          value={selectedDeal || "select"}
          onChange={onDealSelect}
          disabled={newDeal}
        >
          <MenuItem value="select">------ Select a deal ------</MenuItem>
          {finalList.map((deal, l) => (
            <MenuItem key={l} value={deal.deal_id}>
              {deal.deal_name}
            </MenuItem>
          ))}
        </Select>
      ) : null}
      <Collapse in={!tagNewC}>
        <div className="create-deal-form">
          <Collapse in={newDeal}>
            <Typography className="new-deal-name">Deal name</Typography>
            <TextField
              size="small"
              className="full-width-textfield"
              margin="dense"
              variant="outlined"
              onChange={onDealNameChange}
              value={dealName}
            />
            <Typography className="new-deal-name">Deal value</Typography>
            <TextField
              size="small"
              className="full-width-textfield"
              margin="dense"
              variant="outlined"
              type="number"
              onChange={onDealValueChange}
              value={dealValue}
            />
            {props.edit ? (
              <div>
                <Typography className="new-deal-name">Owner</Typography>
                <Select
                  className="full-width-textfield select-under-head"
                  margin="dense"
                  variant="outlined"
                  onChange={onOwnerChange}
                  value={selectedOwner || "select"}
                >
                  <MenuItem value="select">
                    {pipeLoad ? "Loading..." : "----- Select owner -----"}
                  </MenuItem>
                  {relatedContactList.map((acCon, ac) => (
                    <MenuItem key={ac} value={acCon.contact_id}>
                      {acCon.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography className="new-deal-name">
                  Related Contacts
                </Typography>
                <RelatedContacts
                  contacts={accountedContacts}
                  relatedC={relatedContactList}
                  setRCL={setRelatedContactsList}
                  addNewC={addNewContactClick}
                  cancelNewC={cancelAddNewC}
                />
              </div>
            ) : null}
            <Typography className="new-deal-name">Deal Pipeline</Typography>
            {!pipeLoad && !pipelines.length ? (
              <Typography color="error">
                Deal pipelines not available
              </Typography>
            ) : null}
            <Select
              className="full-width-textfield select-under-head"
              margin="dense"
              variant="outlined"
              disabled={!pipelines.length}
              onChange={onPipelineSelect}
              value={selectedPipeline || "select"}
            >
              <MenuItem value="select">
                {pipeLoad ? "Loading..." : "----- Select pipeline -----"}
              </MenuItem>
              {pipelines.map((pipe, p) => (
                <MenuItem key={p} value={pipe.pipeline_id}>
                  {pipe.pipeline_name}
                </MenuItem>
              ))}
            </Select>
            {pipeLoad ? <LinearProgress /> : null}
            <Typography className="new-deal-name">Deal Stage</Typography>
            <Select
              className="full-width-textfield select-under-head"
              margin="dense"
              variant="outlined"
              disabled={
                !(
                  pipeDetails.pipeline_stages &&
                  pipeDetails.pipeline_stages.length
                )
              }
              onChange={onDealStageSelect}
              value={selectedDealStage || "select"}
            >
              <MenuItem value="select">----- Select deal stage -----</MenuItem>
              {pipeDetails.pipeline_stages && pipeDetails.pipeline_stages.length
                ? pipeDetails.pipeline_stages.map((stage, ps) => (
                    <MenuItem key={ps} value={stage.stage_id}>
                      {stage.stage_name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <Typography className="new-deal-name">Currency</Typography>
            <Select
              className="full-width-textfield select-under-head"
              margin="dense"
              variant="outlined"
              value={selectedCurrency || "select"}
              onChange={onCurrencySelect}
              disabled={!currencies.length}
            >
              <MenuItem value="select">----- Select currency -----</MenuItem>
              {currencies.map((curr, c) => (
                <MenuItem key={c} value={curr.code}>
                  {curr.namePlural} ({curr.symbol})
                </MenuItem>
              ))}
            </Select>
            {props.create ? (
              <>
                <Typography className="new-deal-name">Account</Typography>
                {accounts.length ? null : (
                  <Typography color="error">Accounts not available</Typography>
                )}
                <Select
                  className="full-width-textfield select-under-head"
                  margin="dense"
                  variant="outlined"
                  value={selectedAccount || "select"}
                  style={{ minWidth: 200 }}
                  onChange={onAccountChange}
                  disabled={!accounts.length}
                >
                  <MenuItem value="select">----- Select Account -----</MenuItem>
                  {accounts.map((item, i) => (
                    <MenuItem key={i} value={item.account_id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}
            <Divider style={{ marginTop: 15 }} />
          </Collapse>
          {loading ? <LinearProgress /> : null}
          {newDeal ? (
            <div className="dealOptionsWrapper">
              <Button color="primary" onClick={onDealCancel}>
                Cancel
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                color="primary"
                onClick={onDealSave}
                disabled={!allAvailable}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="add-new-deal">
              <Button
                className="addNewDeal"
                color="primary"
                onClick={onAddNewDeal}
                disabled={!selectedAccount}
              >
                Add new
              </Button>
            </div>
          )}
        </div>
      </Collapse>
      <Collapse in={tagNewC}>
        <Paper>
          <AddToContact
            leadDetails={{}}
            cancel={cancelAddNewC}
            edit
            create
            dealTag={editableDeal}
            complete={onCompleteSave}
          />
        </Paper>
      </Collapse>
    </div>
  );
}

export default DealForm;
