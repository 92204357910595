import React, { useEffect, useState } from 'react';
import { Typography, Card, List, ListItem, ListItemText, Button, LinearProgress, Divider, IconButton, Dialog, TextField, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { deleteScheduledContent, getScheduledContents, updateScheduledContent } from '../../../redux/modules/email';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DialogTitle from '../../../components/DialogTitle';
import config from '../../../config';
import { getDateTimeText, getCurrentDatetime, getFormatedDatetime, getUTCDatetime } from '../../../utils/format_date';

export function Scheduled() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [edit, setEdit] = useState(false);
    const [selectedContent, setSelected] = useState('');
    const [scheduleTime, setScheduleTime] = useState('');
    const [updating, setUpdating] = useState(false);
    const [removeContent, setRemove] = useState(false);
    const scheduledContents = useSelector(state => state.email.contents);
    const scCount = useSelector(state => state.email.totalContents);

    const loadEmails = () => {
        setLoading(true);
        dispatch(getScheduledContents(page - 1))
        .then(() => {
            setLoading(false);
        })
        .catch(() => {
            setLoading(false);
        });
    } 

    useEffect(() => {
        loadEmails();
    }, [page]);

    const nextPage = () => {
        setPage(page + 1);
    }

    const prePage = () => {
        setPage(page - 1);
    }

    const onEditClick = (sh) => () => {
        setEdit(true);
        setSelected(sh.tb_id);
        setScheduleTime(sh.sh_time);
    }

    const onSSClose = () => {
        setEdit(false);
        setScheduleTime('');
        setSelected('');
    }

    const onDateTimeChange = (e) => {
        setScheduleTime(e.target.value);
    }

    const onScheduleConfirm = () => {
        setUpdating(true);
        dispatch(updateScheduledContent(selectedContent, getUTCDatetime(scheduleTime)))
        .then(() => {
            setUpdating(false);
            loadEmails();
        })
        .catch(() => {
            setUpdating(false);
        });
    }

    const onDeleteClick = (sh_id) => () => {
        setRemove(true);
        setSelected(sh_id);
    }

    const handleConfirmClose = () => {
        setRemove(false);
        setSelected('');
    }

    const onRemoveContent = () => {
        setUpdating(true);
        dispatch(deleteScheduledContent(selectedContent))
        .then(() => {
            setUpdating(false);
            setRemove(false);
            loadEmails();
        })
        .catch(() => {
            setUpdating(false);
        });
    }

    const inValidDatetime = scheduleTime.replace("T", " ") < getCurrentDatetime();

    return <div>
        <div className='lp__holder' >
            {
                loading
                ? <LinearProgress />
                : null
            }
        </div>
        {
            scheduledContents.length
            ? (
                <Card className="list_paper" >
                    <List className="settings_list" >
                    {
                        scheduledContents.map((sc, a) => {
                            return (
                                <ListItem className="list_item" key={a} >
                                    <ListItemText
                                        primary={(
                                            <div>
                                                <Typography>{sc.email_subject}</Typography>
                                                <Typography style={{ fontSize: 11, lineHeight: "14px" }} >to: {sc.email_to}</Typography>
                                            </div>
                                        )}
                                        secondary={(
                                            <Typography variant="caption" >{getDateTimeText(sc.sh_time)}</Typography>
                                        )}
                                    />
                                    <Button variant="outlined" onClick={onEditClick(sc)} >Edit</Button>
                                    <Button variant="outlined" style={{ marginLeft: 15 }} onClick={onDeleteClick(sc.tb_id)} >Delete</Button>
                                </ListItem>
                            );
                        })
                    }
                    </List>
                </Card>
            )
            : loading ? null : <Typography className='empty-message' >No contents scheduled..</Typography>
        }
        {
                scheduledContents.length
                ? (
                    <>
                    <Divider />
                    <div className='dn-nav-cont' >
                        <Typography variant='caption' >{page}/{Math.ceil(scCount/config.scheduled.limit)}</Typography>
                        <div className='dn-nav-wrapper' >
                            <IconButton onClick={prePage} disabled={page === 1} >
                                <NavigateBeforeIcon />
                            </IconButton>
                            <IconButton onClick={nextPage} disabled={!((scCount - (page * config.scheduled.limit)) > 0)} >
                                <NavigateNextIcon />
                            </IconButton>
                        </div>
                    </div>
                    </>
                )
                : null
            }
            <Dialog open={edit} maxWidth="xs" fullWidth >
                <DialogTitle onClose={onSSClose} >
                    Edit date and time
                </DialogTitle>
                <Divider />
                <DialogContent>
                <TextField
                    label="Date & Time"
                    type="datetime-local"
                    defaultValue={scheduleTime ? getFormatedDatetime(scheduleTime) : getCurrentDatetime()}
                    onChange={onDateTimeChange}
                    style={{
                        marginBottom: 15
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {
                    scheduleTime && inValidDatetime
                    ? <Typography className='error-container' >Select a future date and time to schedule mail.</Typography>
                    : null
                }
                </DialogContent>
                <div className='lp__holder' >
                    {
                        updating
                        ? <LinearProgress />
                        : null
                    }
                </div>
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={onScheduleConfirm} disabled={inValidDatetime} >Change Schedule</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={removeContent}
                onClose={handleConfirmClose}
                >
                <DialogContent>
                    <DialogContentText>
                        {`Do you want to remove this scheduled email ?`}
                    </DialogContentText>
                </DialogContent>
                    {
                        updating
                        ? <LinearProgress />
                        : null
                    }
                <Divider />
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="primary">Cancel</Button>
                    <Button onClick={onRemoveContent} style={{ color: "red" }} autoFocus> Confirm </Button>
                </DialogActions>
            </Dialog>
    </div>;
}