import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import { useDispatch } from "react-redux";
import { uploadContacts } from "../../../redux/modules/leads";
import { csvToArray, getCsvHeaders } from "../../../utils";

function ImportContacts({ onSuccess }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState("");

  const onOpen = () => {
    setOpen(true);
    setFile("");
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFileChange = (e) => {
    setFile();
    const input = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      const csvHeaders = getCsvHeaders(text);

      if(csvHeaders.includes('Name')) {
        setFile(csvToArray(text));
      }

    };

    if (input) {
      reader.readAsText(input);
    }
  };

  const onUpload = (e) => {
    e.preventDefault();
    setUploading(true);
    dispatch(uploadContacts(file))
      .then(() => {
        onSuccess();
        setUploading(false);
        setOpen(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        className="dfilter_btn_common"
        size="small"
        style={{ order: -1 }}
        onClick={onOpen}
      >
        Import
      </Button>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle onClose={onClose}>Import contacts</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className="export_info" component="div">
            Import contacts from CSV to the contact list.
          </Typography>
          <Typography className="export_info more-information" component="div" variant="caption">
            The fields should be Name, E-mail & Phone.<br />For additional emails and phone numbers it should be E-mail2, E-mail3, E-mail4, E-mail5, Phone2, Phone3, Phone4, Phone5.
          </Typography>
          <form className="contact-export-form" onSubmit={onUpload}>
            <input
              type="file"
              id="csvFile"
              accept=".csv"
              onChange={onFileChange}
            />
            <Button
              variant="outlined"
              type="submit"
              onClick={onUpload}
              disabled={!file}
            >
              Upload
            </Button>
          </form>
        </DialogContent>
        <Divider />
        <div className="lp-deals">{uploading ? <LinearProgress /> : null}</div>
      </Dialog>
    </>
  );
}

export default ImportContacts;
