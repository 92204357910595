import React from "react";
import { Button } from "@mui/material";

function GoogleConnect() {
  return (
    <Button
      variant="contained"
      color="default"
      startIcon={<img height={20} src="/google_drive.png" />}
    >
      Connect
    </Button>
  );
}

export default GoogleConnect;
