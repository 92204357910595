import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getChangeLogs } from "../../redux/modules/noauth";
import { Typography, IconButton, Divider, LinearProgress } from "@mui/material";
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@mui/icons-material";

function ChangeLog() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const changeLogs = useSelector((state) => state.noauth.changeLogs);
  const clCount = useSelector((state) => state.noauth.clCount);

  useEffect(() => {
    setLoading(true);
    dispatch(getChangeLogs(page))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  const onPrev = () => {
    setPage((p) => p - 1);
  };

  const onNext = () => {
    setPage((p) => p + 1);
  };

  return (
    <div>
      <Helmet>
        <title>Changelog</title>
        <meta name="description" content="Change Log" />
      </Helmet>
      <div className="page_t_head">
        <div className="page_d_wrapper">
          <Typography className="page_title">Changelog</Typography>
        </div>
        <div className="lp-deals">{loading ? <LinearProgress /> : null}</div>
      </div>
      <div className="cl_container">
        {changeLogs.map((cl, i) => {
          return (
            <div key={i} className="release_holder">
              <Typography variant="h3" className="cl-list-item">
                {cl.release_name}
              </Typography>
              <div
                className="release-details"
                dangerouslySetInnerHTML={{ __html: cl.release_details }}
              ></div>
            </div>
          );
        })}
        <Divider />
      </div>
      <div className="log-nav-cont">
        <Typography variant="caption">
          {clCount > 0 ? `${page + 1}/${Math.ceil(clCount / 3)}` : ""}
        </Typography>
        <div className="log-nav-wrapper">
          <IconButton onClick={onPrev} disabled={page === 0}>
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            onClick={onNext}
            disabled={!(clCount - (page + 1) * 3 > 0)}
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default ChangeLog;
