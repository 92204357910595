import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
} from "@mui/material";
import PriorityDialog from "./components/PriorityDialog";
import StatusDialog from "./components/StatusDialog";
import PriorityTimeDialog from "./components/PriorityTimeDialog";
import PriorityMappingDialog from "./components/PriorityMappingDialog";
import RolePermissions from "./components/RolePermissions";
import Notifications from "./components/Notifications";
import AccessControl from "../../components/AccessControl";
import ContactAccounts from "./components/ContactAccounts";
import DealsPipeline from "./components/DealsPipeline";
import Keys from "./components/Keys";
import { getClientNameFromURL } from "../../utils";
import getAccessFor from "../../helpers/getAccessFor";
import ChangeProfile from "./components/ChangeProfile";
import { Helmet } from "react-helmet";
import TimezoneSettings from "./components/TimezoneSettings";
import AppKey from "./components/AppKeyComp";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ margin: "20px 15px" }}>{children}</Box>}
    </div>
  );
}

// Settings view implementation goes here....
export default function Settings() {
  const classes = "useStyles()";
  const [value, setValue] = React.useState(0);
  const [openPD, setOpenPD] = React.useState(false);
  const [openSD, setOpenSD] = React.useState(false);
  const [openPTD, setOpenPTD] = React.useState(false);
  const [openPMD, setOpenPMD] = React.useState(false);
  const staffname = useSelector((state) => state.auth.staffname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangePriority = () => {
    setOpenPD(true);
  };

  const onChangeStatuses = () => {
    setOpenSD(true);
  };

  const onChangePriorityTimes = () => {
    setOpenPTD(true);
  };

  const onChangePriorityMapping = () => {
    setOpenPMD(true);
  };

  const dealSettings =
    getAccessFor("add_edit_deal_stages") ||
    getAccessFor("add_edit_deal_pipelines");
  const firstKey = dealSettings ? 3 : 2;
  const prod = process.env.NODE_ENV === "production";
  const isClient = !staffname;
  const showKeys = isClient && prod;
  const keyPanel = prod && isClient ? firstKey + 2 : firstKey + 1;
  const appKeyPanel = keyPanel - 1;
  const showAppKey = getAccessFor("show_app_key");
  const user = useSelector((state) => state);

  return (
    <div className={"settings-main"}>
      <Helmet>
        <title>Settings</title>
        <meta name="description" content="Settings page" />
      </Helmet>
      <div style={{ padding: 20 }}>
        <Grid item xs={12}>
          <Typography className="page_title">Settings</Typography>
        </Grid>
      </div>
      <Tabs
        className="settings-tabs"
        value={value}
        onChange={handleChange}
        aria-label="ant example"
      >
        <Tab className="settings-tab" label="General" />
        <Tab className="settings-tab" label="Subscription" />
        {dealSettings ? (
          <Tab className="settings-tab" label="Deals & Pipelines" />
        ) : null}
        {showKeys ? <Tab className="settings-tab" label="Email Auth" /> : null}
        {showAppKey ? <Tab className="settings-tab" label="App Key" /> : null}
        <Tab className="settings-tab" label="More" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Typography className="settings_section" variant="h6">
          Timezone & Language
        </Typography>
        <TimezoneSettings />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Subscription Details Goes Here...
      </TabPanel>
      {dealSettings ? (
        <TabPanel value={value} index={2}>
          <DealsPipeline />
        </TabPanel>
      ) : null}
      {showKeys ? (
        <TabPanel value={value} index={firstKey}>
          <Keys />
        </TabPanel>
      ) : null}
      {showAppKey ? (
        <TabPanel value={value} index={appKeyPanel} className="padtwenty">
          <AppKey />
        </TabPanel>
      ) : null}
      <TabPanel value={value} index={keyPanel} className="padtwenty">
        {isClient ? (
          <>
            <Typography className="settings_section" variant="h6">
              Profile
            </Typography>
            <ChangeProfile />
          </>
        ) : null}
        <AccessControl name="edit_user_role_and_access">
          <Typography className="settings_section" variant="h6">
            User Roles
          </Typography>
          <RolePermissions />
        </AccessControl>
        {staffname ? (
          <>
            <Typography
              className="settings_section"
              variant="h6
                  "
            >
              Notifications
            </Typography>
            <Notifications />
          </>
        ) : null}
        <AccessControl name="priority_and_status_mapping_edit">
          <Typography className="settings_section" variant="h6">
            Priority & Statuses
          </Typography>
          <Card className="list_paper">
            <List className="settings_list">
              <ListItem className="list_item">
                <ListItemText
                  primary={<Typography>Priority List</Typography>}
                  secondary={
                    <Typography variant="caption">
                      You can edit your priority list.
                    </Typography>
                  }
                />
                <Button
                  autoFocus
                  onClick={onChangePriority}
                  color="primary"
                  className="sec-button"
                >
                  Change
                </Button>
              </ListItem>
              <ListItem className="list_item">
                <ListItemText
                  primary={<Typography>Lead Statuses</Typography>}
                  secondary={
                    <Typography variant="caption">
                      You can edit lead statuses list.
                    </Typography>
                  }
                />
                <Button
                  autoFocus
                  onClick={onChangeStatuses}
                  color="primary"
                  className="sec-button"
                >
                  Change
                </Button>
              </ListItem>
              <ListItem className="list_item">
                <ListItemText
                  primary={<Typography>Priority change periods</Typography>}
                  secondary={
                    <Typography variant="caption">
                      Edit priority change time periods here.
                    </Typography>
                  }
                />
                <Button
                  autoFocus
                  onClick={onChangePriorityTimes}
                  color="primary"
                  className="sec-button"
                >
                  Change
                </Button>
              </ListItem>
              <ListItem className="list_item">
                <ListItemText
                  primary={
                    <Typography>Priority and Time period mapping</Typography>
                  }
                  secondary={
                    <Typography variant="caption">
                      Edit priority and time period mapping here.
                    </Typography>
                  }
                />
                <Button
                  autoFocus
                  onClick={onChangePriorityMapping}
                  color="primary"
                  className="sec-button"
                >
                  Change
                </Button>
              </ListItem>
            </List>
          </Card>
        </AccessControl>
        <AccessControl name="add_edit_accounts">
          <Typography className="settings_section" variant="h6">
            Contacts & Accounts
          </Typography>
          <ContactAccounts />
        </AccessControl>
      </TabPanel>
      <PriorityDialog open={openPD} onClose={() => setOpenPD(false)} />
      <StatusDialog open={openSD} onClose={() => setOpenSD(false)} />
      <PriorityTimeDialog open={openPTD} onClose={() => setOpenPTD(false)} />
      <PriorityMappingDialog open={openPMD} onClose={() => setOpenPMD(false)} />
    </div>
  );
}
