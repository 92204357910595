import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  LinearProgress,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  NativeSelect,
  IconButton,
  Badge,
  Tooltip,
  Collapse,
  Pagination,
  Divider,
} from "@mui/material";
import {
  Send as SendIcon,
  Refresh as RefreshIcon,
  Archive as ArchiveIcon,
  Unarchive as UnArchiveIcon,
  Email as EmailIcon,
  Schedule as ScheduleIcon,
  EditOutlined as EditOutlinedIcon,
  Done as DoneIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { setAlert } from "../../redux/modules/alert";
import {
  getLeads,
  setLeadsPage,
  setLeadsPerPage,
  changeFilter,
  setSearchQuery,
  setSelectedProirity,
  setSelectedStatus,
  setSelectedFormId,
  getFormsAndSetId,
  updateALead,
  setSelectedActiveStatus,
  resetLeadsPage,
  setFormPage,
  changeActiveStatusOfLeads,
  getDynamicFilters,
  resetDFilter,
  getLastActivity,
  getEmailsForLeads,
  setActiveLead,
  setSelectedDates,
  setDateFilter,
  setLeadStatusAndTypeEditable,
  leadUpdateSave,
} from "../../redux/modules/leads";
import { getPriorityList, getStatusList } from "../../redux/modules/settings";
import {
  getAContactForm,
  getAFormAndReturnPromise,
} from "../../redux/modules/forms";
import { postLeadNotes } from "../../redux/modules/leads";
import SearchInput from "../../components/SearchInput/SearchInput";
import Notes from "./components/Notes";
import LeadDetails from "./components/LeadDetails";
import AddToContact from "./components/AddToContact";
import AccessControl from "../../components/AccessControl";
import getAccessFor from "../../helpers/getAccessFor";
import More from "./components/More";
import {
  getDateTimeWith12,
  getUnixTimeStampFor,
  getCurrentUnixTimestamp,
} from "../../utils/format_date";
import { maskEmail, truncateString, reverseArray } from "../../utils";
import CreateLead from "./components/CreateLead";
import { getAFormatBody, getBTemplates } from "../../redux/modules/email";
import config from "../../config";
import { Helmet } from "react-helmet";
import PrioritySwitcher from "./components/PrioritySwitcher";
import LeadTimeline from "./components/LeadTimeline";
import ExportLeads from "./components/ExportLeads";
import FollowUp from "./components/FollowUp";
import DateFilter from "./components/DateFilter";
import ImportLeads from "./components/ImportLeads";

const contactTypes = ["Nil", "Mail", "Phone", "SMS"];

const LeadIdComp = ({ value, staffname, leadData, forms }) => {
  const notifications = useSelector((state) => state.auth.notifications);
  const isTest = forms.fields.filter((item) =>
    config.testLeadValues.includes(leadData[item.label])
  ).length;
  const newLeadNoti = notifications.find(
    (noti) => noti.lead_id == leadData.lead_id
  );
  const selectedFormId = useSelector((state) => state.leads.selectedFormId);

  return (
    <div className="l_id_hldr">
      <Typography>{value}</Typography>
      {newLeadNoti ? (
        <Typography className="new_lead_flag" variant="caption">
          New
        </Typography>
      ) : null}
      {leadData.form_name && selectedFormId == "all" ? (
        <Typography className="form_name_flag" variant="caption">
          {truncateString(leadData.form_name, 10)}
        </Typography>
      ) : null}
      {isTest ? (
        <Typography className="test_flag" variant="caption">
          Test
        </Typography>
      ) : null}
      {leadData.staff_name ? (
        <Typography className="staff_flag" variant="caption">
          {staffname == leadData.staff_name
            ? "Assigned to You"
            : leadData.staff_name}
        </Typography>
      ) : null}
      {Number(leadData.scheduled) ? (
        <Typography className="sched_flag" variant="caption">
          <ScheduleIcon style={{ fontSize: 15 }} />
          {leadData.scheduled}
        </Typography>
      ) : null}
    </div>
  );
};

const Leads = (props) => {
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState({});
  const [filterOptions, setFilterOptions] = useState();
  const [filterList, setFilterList] = useState({ searchText: "" });
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [searchClick, setSearchClick] = useState(false);
  const [confirmation, setConfirmation] = useState({
    open: false,
    message: "",
    source: "",
    data: {},
  });
  const [flag, setFlag] = React.useState(false);
  const inactive = props.selectedActive === "0";
  const formPage = useSelector((state) => state.leads.formPage);
  const formTotal = useSelector((state) => state.leads.totalForms);
  const staffname = useSelector((state) => state.auth.staffname);
  const access43 = useSelector((state) => state.auth.accesses["43"]);
  const notifications = useSelector((state) => state.auth.notifications);
  const [advanced, setAdvanced] = useState(false);
  const [openFU, setOpenFU] = useState(false);

  const [rowsSelected, setRowsSelected] = useState([]);
  const dynamicFilters = useSelector((state) => state.leads.dynamicFilters);

  const filterValue = useSelector((state) => state.leads.filterValue);
  const initialRender = useRef(true);

  const activeLead = useSelector((state) => state.leads.activeLead);
  const [gettingEmails, setGetEmails] = useState(false);

  const selectedDates = useSelector((state) => state.leads.selectedDates);

  const saveLeadStatusEditBtnRef = useRef(); //ref for done button in lead update save
  const closeLeadStatusEditBtnRef = useRef(); //ref for close button in lead update save
  const isSaveUpdateLeadActive = useRef(false); //ref for set action state of edit in lead update save
  const handleConfirmBtnRef = useRef(); //ref for confirm button outside the mui table to trigger onkeypress action

  //event listner for enter key and escape key to handle save and close actions in lead update save

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (isSaveUpdateLeadActive.current) {
        if (e.key === "Escape") {
          if (closeLeadStatusEditBtnRef.current) {
            closeLeadStatusEditBtnRef.current.click();
            isSaveUpdateLeadActive.current = false;
          }
        }
        if (e.key === "Enter") {
          if (saveLeadStatusEditBtnRef.current) {
            handleConfirmBtnRef.current.click();
          } else {
            closeLeadStatusEditBtnRef.current.click();
          }
          isSaveUpdateLeadActive.current = false;
        }
      }
    });
  }, []);

  // API calls on initial Load (FormLists, LeadStatusList, PriorityList)
  useEffect(() => {
    // API call for Forms list to populate the dropdown.

    if (getAccessFor("view_leads")) {
      props.getFormsAndSetId(true);
      props.setSearchQuery("");
      dispatch(resetDFilter());

      if (props.selectedFormId != "all") {
        props.setSelectedFormId("all");
      }

      if (!props.statusList.length) {
        props.getStatusList();
      }

      if (!props.priorityList.length) {
        props.getPriorityList();
      }
    }
  }, []);

  const getFUB = () => {
    return dispatch(getAFormatBody());
  };

  useEffect(() => {
    if (
      props.selectedFormId &&
      props.statusList.length &&
      getAccessFor("view_leads")
    ) {
      if (props.selectedFormId != "all") {
        dispatch(getDynamicFilters(props.selectedFormId));
      }

      setIsLoading(true);
      props.getAFormAndReturnPromise(props.selectedFormId).then((forms) => {
        const col = [
          {
            name: "lead_id",
            label: "Lead Id",
            options: {
              filter: false,
              sort: true,
              setCellProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: "0",
                  background: "white",
                  zIndex: 100,
                },
              }),
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  left: 0,
                  background: "white",
                  zIndex: 101,
                },
              }),
              customBodyRender: (value, tableData) => {
                const leadData = tableData.tableData[tableData.rowIndex];
                return (
                  <LeadIdComp
                    value={value}
                    leadData={leadData}
                    forms={forms}
                    staffname={staffname}
                  />
                );
              },
            },
          },
        ];

        col.push({
          name: "name",
          label: "Name",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => (
              <Tooltip title={value}>
                <Typography>{truncateString(value || "NA", 20)}</Typography>
              </Tooltip>
            ),
          },
        });

        if (!inactive) {
          col.push({
            name: "priority_label",
            label: "Priority Label",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta) => {
                const color = tableMeta.tableData.find(
                  (item) => item.lead_id === tableMeta.rowData[0]
                ).color; // Color for priority is taken dynamically from API data

                return (
                  <Typography style={{ borderBottom: `3px solid ${color}` }}>
                    {value}
                  </Typography>
                );
              },
            },
          });
        }

        col.push({
          name: "lead_date",
          label: "Lead Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value) => {
              const createdTime = getDateTimeWith12(value);

              return (
                <>
                  <Typography
                    className="creation_date"
                    variant="caption"
                    component="div"
                  >
                    {createdTime.slice(0, 20)}
                  </Typography>
                  <Typography variant="caption" component="div">
                    {createdTime.slice(20)}
                  </Typography>
                </>
              );
            },
            setCellHeaderProps: () => ({
              style: {
                minWidth: 80,
              },
            }),
          },
        });

        if (getAccessFor("change_lead_status") && !inactive) {
          col.push({
            name: "lead_status",
            label: "Lead Status",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value) => {
                return (
                  <Typography>
                    {props.statusList.map((item) => {
                      if (value === item.status_id) {
                        return item.name;
                      }
                    })}
                  </Typography>
                );
              },
            },
          });
        }

        if (getAccessFor("change_contact_type") && !inactive) {
          col.push({
            name: "contact_type",
            label: "Contact type",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                const defaulValue = value || "";
                return (
                  <Typography>
                    {contactTypes.map((cType, i) => {
                      if (i.toString() === defaulValue) {
                        return cType;
                      }
                    })}
                  </Typography>
                );
              },
            },
          });
        }
        col.push({
          name: "set_lead_status",
          label: " ",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <EditOutlinedIcon
                    sx={{ color: "#546e7a", cursor: "pointer" }}
                    onClick={() => handleSetEditForLeadStatusAndType(tableMeta)}
                  />
                </div>
              );
            },
            setCellHeaderProps: () => ({
              style: {
                minWidth: 50,
              },
            }),
          },
        });

        if (!inactive && getAccessFor("add_to_contact")) {
          col.push({
            name: "contact_status",
            label: "Add to Contacts",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <AddToContact
                    key={tableMeta.rowData[0]}
                    leadDetails={tableMeta.tableData[tableMeta.rowIndex]}
                    contact={value}
                    setLeadsLoading={setIsLoading}
                    lead
                  />
                );
              },
              setCellProps: () => ({
                style: {
                  textAlign: "center",
                },
              }),
            },
          });
        }

        col.push({
          name: "last_activity",
          label: "Activities",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              return (
                <LeadTimeline
                  leadId={tableMeta.rowData[0]}
                  last={value}
                  lead={tableMeta.tableData[tableMeta.rowIndex]}
                />
              );
            },
          },
        });

        if (getAccessFor("view_lead_notes") && !inactive) {
          col.push({
            name: "Notes",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <Notes
                    key={tableMeta.rowData[0]}
                    leadId={tableMeta.rowData[0]}
                    leadName={tableMeta.rowData[1]}
                  />
                );
              },
            },
          });
        }

        if (getAccessFor("view_lead_details")) {
          col.push({
            name: "Details",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <LeadDetails
                    leadId={tableMeta.rowData[0]}
                    leadDetails={tableMeta.tableData[tableMeta.rowIndex]}
                  />
                );
              },
            },
          });
        }

        if (
          (!inactive &&
            (getAccessFor("archive") || getAccessFor("lead_follow_up"))) ||
          getAccessFor("staff_assign") ||
          (inactive && getAccessFor("activate_leads"))
        ) {
          col.push({
            name: "",
            options: {
              filter: false,
              sort: false,
              empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <More
                    leadId={tableMeta.rowData[0]}
                    lead={tableMeta.tableData[tableMeta.rowIndex]}
                    activeStatus={
                      tableMeta.tableData[tableMeta.rowIndex]["active"]
                    }
                    getFUB={getFUB}
                    setLoading={setIsLoading}
                  />
                );
              },
            },
          });
        }

        setRowsSelected([]);
        props
          .getLeads(props.selectedFormId)
          .then(({ result }) => {
            setIsLoading(false);
            setColumns(col);

            dispatch(getLastActivity(result.map((r) => r.lead_id)));
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
    }
  }, [props.selectedFormId, props.statusList, props.selectedActive]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (columns.length) {
        setRowsSelected([]);
        setIsLoading(true);
        props.getLeads(props.selectedFormId).then(({ result }) => {
          setIsLoading(false);

          dispatch(getLastActivity(result.map((r) => r.lead_id)));
        });
      }
    }
  }, [flag, props.selectedStatus, props.selectedPriority, selectedDates]);

  const handleSetEditForLeadStatusAndType = (e) => {
    let editCount = 0;
    e.tableData.forEach((item) => {
      if (item.isStatusAndTypeEditable) {
        editCount += 1;
      }
    });
    if (editCount < 1) {
      dispatch(
        setLeadStatusAndTypeEditable({ lead_id: e.rowData[0], status: true })
      );
      isSaveUpdateLeadActive.current = true;
    } else {
      props.setAlert("You have an active lead in edit", "info");
    }
    setColumns((prev) => {
      return prev.map((item) => {
        if (item.name === "contact_type") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;
                const defaulValue = value || "";
                if (isEditable) {
                  return (
                    <FormControl>
                      <NativeSelect
                        name="Contact type"
                        className="wid_100"
                        value={defaulValue}
                        onChange={(e) =>
                          handleContactTypeChange(
                            e,
                            tableMeta.rowData[0],
                            tableMeta.rowData[1],
                            "contact_type"
                          )
                        }
                      >
                        {contactTypes.map((cType, i) => (
                          <option key={i} value={i}>
                            {cType}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  );
                }
                return (
                  <Typography>
                    {contactTypes.map((cType, i) => {
                      if (i.toString() === defaulValue) {
                        return cType;
                      }
                    })}
                  </Typography>
                );
              },
            },
          };
        }
        if (item.name === "lead_status") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;

                if (isEditable) {
                  return (
                    <FormControl>
                      <NativeSelect
                        name="Status"
                        className="wid_100"
                        value={value}
                        onChange={(e) =>
                          handleStatusChange(
                            e,
                            tableMeta.rowData[0],
                            tableMeta.rowData[1],
                            "lead_status"
                          )
                        }
                      >
                        {props.statusList.map((item, ind) => {
                          return (
                            <option key={ind} value={item.status_id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  );
                }
                return (
                  <Typography>
                    {props.statusList.map((item) => {
                      if (value === item.status_id) {
                        return item.name;
                      }
                    })}
                  </Typography>
                );
              },
            },
          };
        }
        if (item.name === "set_lead_status") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;
                if (isEditable) {
                  return (
                    <div>
                      <IconButton
                        onClick={() => handleConfirmClose(tableMeta.rowData[0])}
                        ref={closeLeadStatusEditBtnRef}
                        size="small"
                      >
                        {" "}
                        <CloseIcon
                          sx={{
                            color: "#546e7a",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                      <IconButton size="small" disabled>
                        <DoneIcon
                          sx={{ color: "#4caf50", cursor: "pointer" }}
                        />
                      </IconButton>
                    </div>
                  );
                }
                return (
                  <div>
                    <EditOutlinedIcon
                      sx={{ color: "#546e7a", cursor: "pointer" }}
                      onClick={() =>
                        handleSetEditForLeadStatusAndType(tableMeta)
                      }
                    />
                  </div>
                );
              },
            },
          };
        }
        return item;
      });
    });
  };

  const handleStatusFilter = (e) => {
    props.setSelectedStatus(e.target.value);
  };

  // const changeContactType = ({ lead_id, value, lead_name }) => {
  //   const leadfields = {
  //     lead_id,
  //     lead_name,
  //     contact_type: value,
  //     lead_time: getCurrentUnixTimestamp(),
  //   };

  //   props
  //     .updateALead(leadfields)
  //     .then(() => {
  //       props.setAlert("Contact type has been updated", "success");
  //       setFlag((prevCheck) => !prevCheck);
  //     })
  //     .catch(() => {
  //       props.setAlert("Error updating contact type", "error");
  //     });

  //   props.postLeadNotes(
  //     lead_id,
  //     null,
  //     `Contact Type has been changed to "${contactTypes[value]}"`,
  //     "log"
  //   );
  // };

  const handleContactTypeChange = (e, lead_id, lead_name, sourceTitle) => {
    dispatch(setActiveLead(lead_id));
    setConfirmation((prev) => {
      return {
        ...prev,
        leadId: lead_id,
        data: {
          ...prev.data,
          [sourceTitle]: {
            value: e.target.value,
            message: `Do you want to change the contact type of Lead ${lead_id} to "${
              contactTypes[e.target.value]
            }"?`,
          },
        },
      };
    });
    setColumns((prev) => {
      return prev.map((item) => {
        if (item.name === "contact_type") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;
                const defaulValue = value || "";
                if (isEditable) {
                  return (
                    <FormControl>
                      <NativeSelect
                        name="Contact type"
                        className="wid_100"
                        value={e.target.value || defaulValue}
                        onChange={(e) =>
                          handleContactTypeChange(
                            e,
                            tableMeta.rowData[0],
                            tableMeta.rowData[1],
                            "contact_type"
                          )
                        }
                      >
                        {contactTypes.map((cType, i) => (
                          <option key={i} value={i}>
                            {cType}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  );
                }
                return (
                  <Typography>
                    {contactTypes.map((cType, i) => {
                      if (i.toString() === defaulValue) {
                        return cType;
                      }
                    })}
                  </Typography>
                );
              },
            },
          };
        }
        if (item.name === "set_lead_status") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;
                if (isEditable) {
                  return (
                    <div>
                      <IconButton
                        onClick={() => handleConfirmClose(tableMeta.rowData[0])}
                        ref={closeLeadStatusEditBtnRef}
                        size="small"
                      >
                        {" "}
                        <CloseIcon
                          sx={{
                            color: "#546e7a",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleDoneSetStatusAndContactType}
                        ref={saveLeadStatusEditBtnRef}
                        size="small"
                      >
                        <DoneIcon
                          sx={{ color: "#4caf50", cursor: "pointer" }}
                        />
                      </IconButton>
                    </div>
                  );
                }
                return (
                  <div>
                    <EditOutlinedIcon
                      sx={{ color: "#546e7a", cursor: "pointer" }}
                      onClick={() =>
                        handleSetEditForLeadStatusAndType(tableMeta)
                      }
                    />
                  </div>
                );
              },
            },
          };
        }
        return item;
      });
    });
  };

  // const changeLeadStatus = ({ value, lead_id, lead_name }) => {
  //   const formfields = {
  //     lead_id: lead_id,
  //     lead_status: value,
  //     lead_name,
  //   };

  //   props
  //     .updateALead(formfields)
  //     .then(() => {
  //       props.setAlert("Lead status has been updated", "success");
  //       setFlag((prevCheck) => !prevCheck);
  //     })
  //     .catch(() => {
  //       props.setAlert("Error updating Lead Status", "error");
  //     });

  //   props.postLeadNotes(
  //     lead_id,
  //     null,
  //     `Lead status has been changed to "${
  //       props.statusList.find((item) => item.status_id === value).name
  //     }"`,
  //     "log"
  //   );
  // };

  const handleleadUpdateSave = (leadData) => {
    isSaveUpdateLeadActive.current = false;
    props
      .leadUpdateSave(leadData)
      .then(() => {
        setConfirmation({
          open: false,
          message: "",
          source: "",
          data: {},
        });
        props.setAlert(
          "Lead status and contact type has been updated",
          "success"
        );
        setFlag((prevCheck) => !prevCheck);
      })
      .catch(() => {
        props.setAlert("Error updating Lead status and contact type", "error");
      });

    let message = "";
    if (leadData.lead_status) {
      message += `Lead status has been updated to "${
        props.statusList.find((item) => item.status_id === leadData.lead_status)
          .name
      }". `;
    }
    if (leadData.contact_type) {
      message += `Contact type updated to "${
        contactTypes[leadData.contact_type]
      }".`;
    }

    props.postLeadNotes(leadData.lead_id, null, `${message}`, "log");
  };

  const handleStatusChange = (e, lead_id, lead_name, sourceTitle) => {
    setConfirmation((prev) => {
      return {
        ...prev,
        leadId: lead_id,
        data: {
          ...prev.data,
          [sourceTitle]: {
            value: e.target.value,
            message: `Do you want to change the lead status of Lead ${lead_id} to "${
              props.statusList.find((item) => item.status_id === e.target.value)
                .name
            }"?`,
          },
        },
      };
    });
    dispatch(setActiveLead(lead_id));
    setColumns((prev) => {
      return prev.map((item) => {
        if (item.name === "lead_status") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;

                if (isEditable) {
                  return (
                    <FormControl>
                      <NativeSelect
                        name="Status"
                        className="wid_100"
                        value={e.target.value || value}
                        onChange={(e) =>
                          handleStatusChange(
                            e,
                            tableMeta.rowData[0],
                            tableMeta.rowData[1],
                            "lead_status"
                          )
                        }
                      >
                        {props.statusList.map((item, ind) => {
                          return (
                            <option key={ind} value={item.status_id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  );
                }
                return (
                  <Typography>
                    {props.statusList.map((item) => {
                      if (value === item.status_id) {
                        return item.name;
                      }
                    })}
                  </Typography>
                );
              },
            },
          };
        }
        if (item.name === "set_lead_status") {
          return {
            ...item,
            options: {
              ...item.options,
              customBodyRender: (value, tableMeta) => {
                const isEditable =
                  tableMeta.tableData[tableMeta.rowIndex]
                    .isStatusAndTypeEditable || false;
                if (isEditable) {
                  return (
                    <div>
                      <IconButton
                        onClick={() => handleConfirmClose(tableMeta.rowData[0])}
                        ref={closeLeadStatusEditBtnRef}
                        size="small"
                      >
                        {" "}
                        <CloseIcon
                          sx={{
                            color: "#546e7a",
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={handleDoneSetStatusAndContactType}
                        ref={saveLeadStatusEditBtnRef}
                        size="small"
                      >
                        <DoneIcon
                          sx={{ color: "#4caf50", cursor: "pointer" }}
                        />
                      </IconButton>
                    </div>
                  );
                }
                return (
                  <div>
                    <EditOutlinedIcon
                      sx={{ color: "#546e7a", cursor: "pointer" }}
                      onClick={() =>
                        handleSetEditForLeadStatusAndType(tableMeta)
                      }
                    />
                  </div>
                );
              },
            },
          };
        }
        return item;
      });
    });
  };

  const onMultipleArchive = (selectedLeads) => {
    if (selectedLeads.length) {
      setIsLoading(true);
      setRowsSelected([]);
      dispatch(changeActiveStatusOfLeads(selectedLeads, inactive ? "1" : "0"))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const onMultipleFollowUp = (selectedLeads) => {
    let fuPromises = [];
    fuPromises.push(dispatch(getAContactForm(props.selectedFormId)));
    fuPromises.push(getFUB());
    fuPromises.push(dispatch(getBTemplates()));
    fuPromises.push(dispatch(getEmailsForLeads(selectedLeads)));

    setOpenFU(true);
    setGetEmails(true);

    Promise.all(fuPromises)
      .then(() => {
        setGetEmails(false);
      })
      .catch(() => {
        setGetEmails(false);
      });
  };

  const changePage = (page, rows, sortOrder, filterList, searchText) => {
    props.setLeadsPage(page);
    props.setLeadsPerPage(rows);
    setRowsSelected([]);
    setIsLoading(true);
    props
      .getLeads(props.selectedFormId, sortOrder.direction)
      .then(({ result }) => {
        setSortOrder(sortOrder);
        setFilterList(filterList);
        setSearchText(searchText);
        setIsLoading(false);
        dispatch(getLastActivity(result.map((r) => r.lead_id)));
      });
  };

  const options = {
    serverSide: true,
    draggable: true,
    responsive: "scrollFullHeight",
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    count: props.total,
    page: props.leadsPage,
    rowsPerPage: props.leadsPerPage,
    rowsPerPageOptions: [10, 50, 100],
    searchText: searchText,
    selectableRows: "multiple",
    rowsSelected: rowsSelected,
    setRowProps: (row) => {
      const rowProps = row[0].props;
      if (rowProps && rowProps.value == activeLead) {
        return {
          style: { background: "#d6e7ff" },
        };
      }
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setRowsSelected(rowsSelected);
    },
    jumpToPage: true,
    search: false,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          "Loading..."
        ) : (
          <Typography
            className="empty-message"
            component="div"
            variant="caption"
          >
            Sorry, no leads found...
          </Typography>
        ),
      },
    },
    customToolbarSelect: () => {
      const selectedLeads = rowsSelected.map(
        (selected) => props.leadsList[selected]["lead_id"]
      );

      return (
        <div>
          <IconButton onClick={() => onMultipleArchive(selectedLeads)}>
            {inactive ? (
              <UnArchiveIcon />
            ) : (
              <ArchiveIcon style={{ color: "#546e7a" }} />
            )}
            <Typography
              style={{ marginLeft: 5, color: "#263238", fontSize: 14 }}
            >
              {inactive ? "Activate" : "Archive"}
            </Typography>
          </IconButton>
          <IconButton onClick={() => onMultipleFollowUp(selectedLeads)}>
            <EmailIcon style={{ color: "#546e7a" }} />
            <Typography
              style={{
                marginLeft: 5,
                marginRight: 5,
                color: "#263238",
                fontSize: 14,
              }}
            >
              Follow Up
            </Typography>
          </IconButton>
        </div>
      );
    },
    customToolbar: () => {
      return (
        <>
          <ExportLeads formId={props.selectedFormId} />
          <ImportLeads formId={props.selectedFormId} />
          <IconButton onClick={onRefresh} style={{ order: -1 }}>
            <RefreshIcon />
          </IconButton>
        </>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          changePage(
            tableState.page,
            props.leadsPerPage,
            sortOrder,
            filterList,
            searchText
          );
          break;
        case "changeRowsPerPage":
          changePage(
            0,
            tableState.rowsPerPage,
            sortOrder,
            filterList,
            searchText
          );
          break;
        case "sort":
          changePage(
            props.leadsPage,
            props.leadsPerPage,
            tableState.sortOrder,
            filterList,
            searchText
          );
          break;
        case "filterChange":
          var res = tableState.filterList.toString().split(",");
          var resList = res.filter(function (el) {
            return el !== "";
          });

          changePage(
            props.leadsPage,
            props.leadsPerPage,
            sortOrder,
            { country: resList },
            searchText
          );
          break;
        default:
        // console.log("action not handled.");
      }
    },
  };

  const handleSelectPriority = (e) => props.setSelectedProirity(e.target.value);
  const handleSelectStatus = (e) => props.setSelectedStatus(e.target.value);
  const handleSelectActions = (e) => {
    dispatch(resetDFilter());

    if (e.target.value == "all") {
      setAdvanced(false);
    }

    props.setSelectedFormId(e.target.value || props.selectedFormId);
  };
  const handleConfirmClose = (leadId) => {
    dispatch(setActiveLead(""));
    dispatch(
      setLeadStatusAndTypeEditable({
        lead_id: leadId,
        status: false,
      })
    );
    setConfirmation({ open: false, message: "", source: "", data: {} });
  };

  const handleConfirm = () => {
    dispatch(setActiveLead(""));
    const { leadId, data } = confirmation;
    const { contact_type, lead_status } = data;
    if (leadId) {
      const leadData = { lead_id: leadId };
      if (contact_type) leadData.contact_type = contact_type.value;
      if (lead_status) leadData.lead_status = lead_status.value;
      if (contact_type || lead_status) {
        setIsLoading(true);
        handleleadUpdateSave(leadData);
        return;
      }
    } else if (!isLoading) {
      setConfirmation({
        open: false,
        message: "",
        source: "",
        data: {},
      });
    }
  };

  const handleDoneSetStatusAndContactType = () => {
    setConfirmation((prev) => {
      return {
        ...prev,
        open: true,
        message: `${prev?.data?.contact_type?.message || ""} ${
          prev?.data?.lead_status?.message || ""
        }`,
      };
    });
  };

  const onActiveChange = (e) => {
    props.resetLeadsPage();
    props.setSelectedActiveStatus(e.target.value);
  };

  const onRefresh = () => {
    setIsLoading(true);
    props.getLeads(props.selectedFormId).then(({ result }) => {
      setIsLoading(false);
      dispatch(getLastActivity(result.map((r) => r.lead_id)));
    });
  };

  const onFormPageChange = (e, value) => {
    e.stopPropagation();
    props.setFormPage(value - 1);
    props.getFormsAndSetId();
  };

  const onClearFilter = () => {
    props.setSelectedProirity("");
    props.setSelectedStatus("");
    props.setSelectedActiveStatus(1);
    props.setSearchQuery("");
    props.resetLeadsPage();
    dispatch(setSelectedDates("", ""));
    dispatch(resetDFilter());
    dispatch(setDateFilter(0));
  };

  const onAdvancedClick = () => {
    setAdvanced(!advanced);
  };

  const onDynamicFilterValueChange = (df) => (e) => {
    props.resetLeadsPage();
    dispatch(
      changeFilter(
        df.filter_id,
        e.target.value == "select" ? "" : e.target.value
      )
    );
    setFlag((prevCheck) => !prevCheck);
  };

  const onFollowUpClose = () => {
    setOpenFU(false);
  };

  const newLeadsCount = notifications.filter((noti) => noti.form_id).length;
  const [formsOpened, setFOpen] = useState(false);

  const isDFilterApplied = dynamicFilters.filter(
    (df) => filterValue[`filter[${df.filter_id}]`]
  ).length;
  const isFilterApplied =
    props.selectedPriority ||
    props.selectedStatus ||
    props.selectedActive != 1 ||
    props.searchQuery ||
    isDFilterApplied ||
    (selectedDates.from && selectedDates.to);

  return (
    <>
      <Helmet>
        <title>Leads</title>
        <meta name="description" content="Leads page" />
      </Helmet>
      <AccessControl name="view_leads" message>
        <div className="page_t_head">
          <div className="page_t_wrapper">
            <Typography className="page_title">Leads</Typography>
            <AccessControl name="create_lead">
              <CreateLead />
            </AccessControl>
          </div>
        </div>
        <div className="page-main">
          <div className="leads-tools-container">
            <div className="lead-header">
              <div className="lh-left-side">
                <div className="rf-container">
                  <Badge
                    color="primary"
                    badgeContent=""
                    variant="dot"
                    invisible={!isFilterApplied}
                  >
                    <IconButton
                      className="remove-filter"
                      onClick={onClearFilter}
                    >
                      <img className="rf-img" src="/filter.svg" />
                    </IconButton>
                  </Badge>
                </div>
                <div className="form-listing">
                  <FormControl
                    variant="outlined"
                    color="primary"
                    className="selectStyle"
                  >
                    <InputLabel htmlFor="grouped-select"></InputLabel>
                    <Badge color="error" badgeContent={newLeadsCount}>
                      <Select
                        id="grouped-select"
                        name="form"
                        size="small"
                        value={props.selectedFormId}
                        onChange={handleSelectActions}
                        onOpen={() => setFOpen(true)}
                        onClose={() => setFOpen(false)}
                      >
                        <ListSubheader>Forms</ListSubheader>
                        {/* {
                        props.selectedFormId
                        ? null
                        : <MenuItem key="5800" value="no-form"> <em>No forms available</em></MenuItem>
                      } */}
                        <MenuItem key="5800" value="all">
                          All Forms
                        </MenuItem>
                        {props.formsList.map((item, i) => {
                          const noticount = notifications.filter(
                            (noti) => noti.form_id == item.id
                          ).length;
                          const formName = `${item.title.slice(0, 24)}${
                            item.title.length > 24 ? "..." : ""
                          }`;

                          return (
                            <MenuItem key={i} value={item.id}>
                              <span className="form-menu-item">
                                <em>{formName}</em>
                                {formsOpened && noticount ? (
                                  <span className="f-l-count">{noticount}</span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </MenuItem>
                          );
                        })}
                        <ListSubheader>
                          <Pagination
                            color="primary"
                            variant="outlined"
                            count={Math.ceil(formTotal / 10)}
                            page={formPage + 1}
                            onChange={onFormPageChange}
                            siblingCount={0}
                            boundaryCount={1}
                          />
                        </ListSubheader>
                      </Select>
                    </Badge>
                  </FormControl>
                </div>
                <div className="priority-listing">
                  <FormControl
                    variant="outlined"
                    color="primary"
                    className="selectStyle"
                  >
                    <Select
                      className="wid_min_120"
                      id="grouped-select"
                      name="form"
                      size="small"
                      displayEmpty
                      value={props.selectedPriority}
                      onChange={handleSelectPriority}
                    >
                      <MenuItem value="">
                        <em>All Priorities</em>
                      </MenuItem>
                      {props.priorityList.map((item, i) => (
                        <MenuItem key={i} value={item.priority_id}>
                          {" "}
                          <span>{item.priority_label}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="lead-type ">
                  <FormControl
                    variant="outlined"
                    color="primary"
                    className="selectStyle"
                  >
                    <Select
                      className="wid_min_120"
                      id="grouped-select"
                      name="form"
                      size="small"
                      displayEmpty
                      value={props.selectedStatus}
                      onChange={handleSelectStatus}
                    >
                      <MenuItem value="">
                        <em>All Status</em>
                      </MenuItem>
                      {props.statusList.map((item, i) => (
                        <MenuItem key={i} value={item.status_id}>
                          {" "}
                          <span>{item.name}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="lead-active-status">
                  <FormControl
                    variant="outlined"
                    color="primary"
                    className="selectStyle"
                  >
                    <Select
                      className="wid_min_120"
                      id="grouped-select"
                      name="active"
                      size="small"
                      displayEmpty
                      value={props.selectedActive}
                      onChange={onActiveChange}
                    >
                      <MenuItem value="1">
                        {" "}
                        <span>Active</span>
                      </MenuItem>
                      <MenuItem value="0">
                        {" "}
                        <span>Inactive</span>
                      </MenuItem>
                      {access43 == 0 ? (
                        <MenuItem value="2">
                          {" "}
                          <span>Assigned</span>
                        </MenuItem>
                      ) : null}
                    </Select>
                  </FormControl>
                </div>
                <DateFilter />
                <div className="selectStyle">
                  <SearchInput
                    formId={props.selectedFormId}
                    setIsLoading={setIsLoading}
                  />
                </div>
                {props.selectedFormId != "all" && dynamicFilters.length ? (
                  <div className="advanced_filter_btn">
                    <Button color="primary" onClick={onAdvancedClick}>
                      Advanced Filter
                    </Button>
                  </div>
                ) : null}
              </div>
              {getAccessFor("priority_sorting") ? (
                <PrioritySwitcher refresh={onRefresh} />
              ) : null}
            </div>
          </div>
          <Collapse in={advanced}>
            <div className="advanced_filters">
              {dynamicFilters.length
                ? dynamicFilters.map((df, d) => (
                    <FormControl
                      id={d}
                      variant="outlined"
                      color="primary"
                      className="selectStyle dynamic_filter"
                    >
                      <InputLabel htmlFor="filled-native-simple">
                        {df.filter_name}
                      </InputLabel>
                      <Select
                        className="wid_min_120"
                        id="grouped-select"
                        name={df.filter_name}
                        label={df.filter_name}
                        size="small"
                        value={
                          filterValue[`filter[${df.filter_id}]`] || "select"
                        }
                        defaultValue="select"
                        inputProps={{
                          name: "filter",
                          id: "filled-native-simple",
                        }}
                        displayEmpty
                        onChange={onDynamicFilterValueChange(df)}
                      >
                        <MenuItem id={123569} value="select">
                          {" "}
                          <span>---select---</span>
                        </MenuItem>
                        {df.filter_values.map((fv, f) => (
                          <MenuItem key={f} id={f} value={fv.value}>
                            {" "}
                            <span>{fv.value}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))
                : null}
            </div>
          </Collapse>
          {isLoading && <LinearProgress />}
          <MUIDataTable
            className={`tble-bdr lead-list-tbl ${
              isLoading ? "tble-on-load" : ""
            }`}
            title=""
            data={props.leadsList}
            columns={columns}
            options={options}
          />

          {/* CONFIRMATION DIALOG */}

          {/* dummy btn for getting handleconfirm state for red */}
          <IconButton
            ref={handleConfirmBtnRef}
            onClick={handleConfirm}
            color="inherit"
            sx={{ cursor: "default", ":hover": { backgroundColor: "inherit" } }}
          ></IconButton>
          {/* dummy btn for getting handleconfirm state for red */}

          <Dialog
            open={confirmation.open}
            onClose={() => handleConfirmClose(confirmation.leadId)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <>
                  <div>{confirmation.message}</div>
                </>
              </DialogContentText>
            </DialogContent>
            {isLoading ? <LinearProgress /> : null}
            <Divider />
            <DialogActions>
              <Button
                onClick={() => handleConfirmClose(confirmation.leadId)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </AccessControl>
      <FollowUp
        open={openFU}
        close={onFollowUpClose}
        lead={{}}
        loadingFU={gettingEmails}
        multiple
      />
    </>
  );
};

export default connect(
  (state) => ({
    leadsList: state.leads.leadsList,
    leadsPerPage: state.leads.rowsPerPage,
    leadsPage: state.leads.page,
    total: state.leads.total,
    selectedPriority: state.leads.selectedPriority,
    selectedStatus: state.leads.selectedStatus,
    selectedFormId: state.leads.selectedFormId,
    priorityList: state.settings.priorityList,
    statusList: state.settings.statusList,
    formsList: state.leads.formsList,
    formFields: state.forms.fields,
    accesses: state.auth.accesses,
    selectedActive: state.leads.selectedActive,
    searchQuery: state.leads.searchQuery,
  }),
  {
    getLeads,
    getAFormAndReturnPromise,
    updateALead,
    postLeadNotes,
    setLeadsPage,
    setLeadsPerPage,
    setSearchQuery,
    setSelectedProirity,
    setSelectedStatus,
    setSelectedFormId,
    getFormsAndSetId,
    setAlert,
    getPriorityList,
    getStatusList,
    setSelectedActiveStatus,
    resetLeadsPage,
    setFormPage,
    leadUpdateSave,
  }
)(Leads);
