import React, { useEffect, useState } from "react";
import config from "../../../config";
import {
  clearDealTL,
  getDealTimeline,
  setDealTLPage,
} from "../../../redux/modules/deals";
import { Button, LinearProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDateTimeText } from "../../../utils/format_date";

function DealTimeline({ dealId, open }) {
  const dispatch = useDispatch();
  const tlPage = useSelector((state) => state.deals.tlPage);
  const [isLoading, setIsLoading] = useState(false);
  const dealTimeline = useSelector((state) => state.deals.dealTimeline);
  const timelineCount = useSelector((state) => state.deals.timelineCount);

  useEffect(() => {
    if (open) {
      if (dealTimeline.length) {
        dispatch(clearDealTL());
        if (tlPage > 0) {
          dispatch(setDealTLPage(0));
        }
      }

      if (tlPage == 0) {
        setIsLoading(true);
        dispatch(getDealTimeline(dealId, config.dTimeline.limit, tlPage)).then(
          () => {
            setIsLoading(false);
          }
        );
      }
    } else {
      dispatch(setDealTLPage(0));
    }
  }, [open]);

  useEffect(() => {
    if (tlPage > 0) {
      setIsLoading(true);
      dispatch(getDealTimeline(dealId, config.dTimeline.limit, tlPage)).then(
        () => {
          setIsLoading(false);
        }
      );
    }
  }, [tlPage]);

  const onLoadMore = () => {
    dispatch(setDealTLPage(tlPage + 1));
  };

  return (
    <>
      {!dealTimeline.length && !isLoading ? (
        <Typography className="empty-message" component="div" variant="caption">
          No activities done yet...
        </Typography>
      ) : null}
      <div className="tl-container">
        <div className="tl-line"></div>
        <div className="tl-content-holder">
          {dealTimeline.map((dt, k) => {
            const dateTime = getDateTimeText(dt.update_time);

            return (
              <div key={k} className="tl-dot">
                <div className="tl-content">
                  <div className="tl-datetime">{dateTime}</div>
                  <div className="tl-text">{dt.activity}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isLoading ? <LinearProgress /> : null}
      {dealTimeline.length && !isLoading ? (
        <Button
          color="primary"
          variant="outlined"
          onClick={onLoadMore}
          disabled={isLoading || dealTimeline.length == timelineCount}
        >
          Load more
        </Button>
      ) : null}
    </>
  );
}

export default DealTimeline;
