import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import DialogTitle from "../../../components/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { getLeadsBetweenDates } from "../../../redux/modules/leads";
import {
  getCurrentDate,
  getUTCDatetime,
  getUTCDatetimeEnd,
} from "../../../utils/format_date";
import { truncateString } from "../../../utils";

function ExportLeads({ formId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(getCurrentDate());
  const [to, setTo] = useState(getCurrentDate());
  const [downloading, setDownloading] = useState(false);
  const formfields = useSelector((state) => state.forms.fields);
  const formsList = useSelector((state) => state.leads.formsList);
  const formSelected = formsList.find((fl) => fl.id === formId) || {};

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setFrom(getCurrentDate());
    setTo(getCurrentDate());
    setOpen(false);
  };

  const onFromChange = (e) => {
    setFrom(e.target.value);
  };

  const onToChange = (e) => {
    setTo(e.target.value);
  };

  const onDownload = () => {
    setDownloading(true);
    dispatch(
      getLeadsBetweenDates(getUTCDatetime(from), getUTCDatetimeEnd(to), formId)
    )
      .then(({ result: leads }) => {
        setDownloading(false);
        const rows = [
          formfields.map((ff) => ff.label),
          ...leads.map((l) => formfields.map((f) => l[f.label])),
        ];

        const csvContent =
          "data:text/csv;charset=utf-8," +
          rows.map((e) => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `leads[${from}_to_${to}].csv`);
        document.body.appendChild(link);

        link.click();
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  return (
    <>
      {formId != "all" ? (
        <div className="toolbar-btn">
          <Button style={{ order: 4, color: "#263238" }} onClick={onOpen}>
            Export
          </Button>
        </div>
      ) : null}
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle onClose={onClose}>
          Export leads from{" "}
          {
            <span className="form-name">
              {truncateString(formSelected.title, 10)}
            </span>
          }{" "}
          form
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography className="export_info" component="div">
            Select a time period to download leads.
          </Typography>
          <div>
            <TextField
              id="from_date"
              label="From"
              type="date"
              onChange={onFromChange}
              value={from}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="to_date"
              className="date_select"
              label="To"
              type="date"
              onChange={onToChange}
              value={to}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </DialogContent>
        <Divider />
        <div className="lp-deals">
          {downloading ? <LinearProgress /> : null}
        </div>
        <DialogActions>
          <Button variant="outlined" onClick={onDownload}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExportLeads;
