import { setAlert } from './alert';

// ACTION TYPES
const GET_EMAIL_TEMP = 'GET_EMAIL_TEMP';
const GET_EMAIL_TEMP_SUCCESS = 'GET_EMAIL_TEMP_SUCCESS';
const GET_EMAIL_TEMP_FAILED = 'GET_EMAIL_TEMP_FAILED';

const GET_TEMP_DETAILS = 'GET_TEMP_DETAILS';
const GET_TEMP_DETAILS_SUCCESS = 'GET_TEMP_DETAILS_SUCCESS';
const GET_TEMP_DETAILS_FAILED = 'GET_TEMP_DETAILS_FAILED';

const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
const UPDATE_TEMPLATE_FAILED = 'UPDATE_TEMPLATE_FAILED';

const GET_BODY_FORMATS = 'GET_BODY_FORMATS';
const GET_BODY_FORMATS_SUCCESS = 'GET_BODY_FORMATS_SUCCESS';
const GET_BODY_FORMATS_FAILED = 'GET_BODY_FORMATS_FAILED';

const UPDATE_FORMAT = 'UPDATE_FORMAT';
const UPDATE_FORMAT_SUCCESS = 'UPDATE_FORMAT_SUCCESS';
const UPDATE_FORMAT_FAILED = 'UPDATE_FORMAT_FAILED';

const SEND_FUMAIL = 'SEND_FUMAIL';
const SEND_FUMAIL_SUCCESS = 'SEND_FUMAIL_SUCCESS';
const SEND_FUMAIL_FAILED = 'SEND_FUMAIL_FAILED';

const GET_A_F_BODY = 'GET_A_F_BODY';
const GET_A_F_BODY_SUCCESS = 'GET_A_F_BODY_SUCCESS';
const GET_A_F_BODY_FAILED = 'GET_A_F_BODY_FAILED';

const GET_EB_DRAFT = 'GET_EB_DRAFT';
const GET_EB_DRAFT_S = 'GET_EB_DRAFT_S';
const GET_EB_DRAFT_F = 'GET_EB_DRAFT_F';

const SET_EB_DRAFT = 'SET_EB_DRAFT';
const SET_EB_DRAFT_SUC = 'SET_EB_DRAFT_SUC';
const SET_EB_DRAFT_FAIL = 'SET_EB_DRAFT_FAIL';

const GET_B_TS = 'GET_B_TS';
const GET_B_TS_S = 'GET_B_TS_S';
const GET_B_TS_F = 'GET_B_TS_F';

const POST_BT = 'POST_BT';
const POST_BT_S = 'POST_BT_S';
const POST_BT_F = 'POST_BT_F';

const GET_TV = 'GET_TV';
const GET_TV_S = 'GET_TV_S';
const GET_TV_F = 'GET_TV_F';

const POST_TEMPVAR = 'POST_TEMPVAR';
const POST_TEMPVAR_S = 'POST_TEMPVAR_S';
const POST_TEMPVAR_F = 'POST_TEMPVAR_F';

const UPDATE_TEMPVAR = 'UPDATE_TEMPVAR';
const UPDATE_TEMPVAR_S = 'UPDATE_TEMPVAR_S';
const UPDATE_TEMPVAR_F = 'UPDATE_TEMPVAR_F';

const REM_TEMPVAR = 'REM_TEMPVAR';
const REM_TEMPVAR_S = 'REM_TEMPVAR_S';
const REM_TEMPVAR_F = 'REM_TEMPVAR_F';

const UPDATE_BT = 'UPDATE_BT';
const UPDATE_BT_S = 'UPDATE_BT_S';
const UPDATE_BT_F = 'UPDATE_BT_F';

const DELETE_BT = 'DELETE_BT';
const DELETE_BT_S = 'DELETE_BT_S';
const DELETE_BT_F = 'DELETE_BT_F';

const GET_SCHED_CONTENTS = 'GET_SCHED_CONTENTS';
const GET_SCHED_CONTENTS_S = 'GET_SCHED_CONTENTS_S';
const GET_SCHED_CONTENTS_F = 'GET_SCHED_CONTENTS_F';

const UPD_SCHED_CONTENT = 'UPD_SCHED_CONTENT';
const UPD_SCHED_CONTENT_S = 'UPD_SCHED_CONTENT_S';
const UPD_SCHED_CONTENT_F = 'UPD_SCHED_CONTENT_F';

const DEL_SCHED_CONTENT = 'DEL_SCHED_CONTENT';
const DEL_SCHED_CONTENT_S = 'DEL_SCHED_CONTENT_S';
const DEL_SCHED_CONTENT_F = 'DEL_SCHED_CONTENT_F';

// INITIAL
const initialState = {
    templates: [],
    formats: [],
    followUpBody: "",
    bodyTemplates: [
        // {
        //     id: "12",
        //     name: "Invite",
        //     email_body: "Hi Invite"
        // }
    ],
    draftBody: "",
    tempVariables: [],
    contents: [],
    totalContents: 1
};

// REDUCER
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_EMAIL_TEMP_SUCCESS:
            return {
                ...state,
                templates: payload.result
            };
        case GET_TEMP_DETAILS_SUCCESS:
            const templateDetails = payload.result;
            const newTemplates = state.templates.map(t => (t.temp_id === templateDetails.temp_id ? templateDetails : t));

            return {
                ...state,
                templates: newTemplates
            };
        case GET_BODY_FORMATS_SUCCESS:
            return {
                ...state,
                formats: payload.result
            };
        case GET_A_F_BODY_SUCCESS:
            return {
                ...state,
                followUpBody: payload.result && payload.result.body || ""
            };
        case SET_EB_DRAFT_SUC:
            return {
                ...state,
                draftBody: action.draftBody || ""
            };
        case GET_EB_DRAFT_S:
            return {
                ...state,
                draftBody: payload.result && payload.result.draft_body || ""
            };
        case GET_B_TS_S:
            return {
                ...state,
                bodyTemplates: payload.result || []
            };
        case GET_TV_S:
            return {
                ...state,
                tempVariables: payload.result || []
            };
        case GET_SCHED_CONTENTS_S:
            return {
                ...state,
                contents: payload.result || [],
                totalContents: payload.count || 1
            };
        default:
            return state;
    }
}

// ACTION CREATORS
export function getEmailTemplates() {
    return {
        types: [GET_EMAIL_TEMP, GET_EMAIL_TEMP_SUCCESS, GET_EMAIL_TEMP_FAILED],
        promise: fetch => fetch.get('api/Emailtemplate')
    };
}

export function getTempDetails(template_id) {
    return {
        types: [GET_TEMP_DETAILS, GET_TEMP_DETAILS_SUCCESS, GET_TEMP_DETAILS_FAILED],
        promise: fetch => fetch.get('api/Template', {
            params: {
                template_id
            }
        })
    };
}

export function updateTemplate(template) {
    return dispatch => dispatch({
        types: [UPDATE_TEMPLATE, UPDATE_TEMPLATE_SUCCESS, UPDATE_TEMPLATE_FAILED],
        promise: fetch => fetch.put('api/Template', {
            data: {
                template_id: template.temp_id,
                ...template
            }
        })
    }).then(() => {
        dispatch(setAlert(`Email template has been updated successfully.`, "success"));
        return dispatch(getTempDetails(template.temp_id));
    });
}

export function getBodyFormats() {
    return {
        types: [GET_BODY_FORMATS, GET_BODY_FORMATS_SUCCESS, GET_BODY_FORMATS_FAILED],
        promise: fetch => fetch.get('api/Formatlist')
    };
}

export function updateFormat(format) {
    return dispatch => dispatch({
        types: [UPDATE_FORMAT, UPDATE_FORMAT_SUCCESS, UPDATE_FORMAT_FAILED],
        promise: fetch => fetch.put('api/Emailformat', {
            data: format
        })
    }).then(() => {
        dispatch(setAlert(`Email body for [${format.name}] has been changed successfully.`, "success"));

        return dispatch(getBodyFormats());
    });
}

export function sendFUMail(emailData) {
    return dispatch => dispatch({
        types: [SEND_FUMAIL, SEND_FUMAIL_SUCCESS, SEND_FUMAIL_FAILED],
        promise: fetch => fetch.post('api/Fumail', {
            data: emailData
        })
    }).then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Email has been sent successfully.`, "success"));
        } else {
            dispatch(setAlert(res.message ? res.message : `Error sending email.`, "error"));
            
            return Promise.reject(res);
        }
        
        return res;
    });
}

export function getAFormatBody() {
    return {
        types: [GET_A_F_BODY, GET_A_F_BODY_SUCCESS, GET_A_F_BODY_FAILED],
        promise: fetch => fetch.get('api/Fumail')
    };
}

export function getEBDraft(params) {
    return {
        types: [GET_EB_DRAFT, GET_EB_DRAFT_S, GET_EB_DRAFT_F],
        promise: fetch => fetch.get('api/Email_draft', {
            params
        })
    };
}

export function setEBDraft(draftObj) {
    return {
        types: [SET_EB_DRAFT, SET_EB_DRAFT_SUC, SET_EB_DRAFT_FAIL],
        promise: fetch => fetch.post('api/Email_draft', {
            data: draftObj,
        }),
        draftBody: draftObj.draft_body
    };
}

export function getBTemplates() {
    return {
        types: [GET_B_TS, GET_B_TS_S, GET_B_TS_F],
        promise: fetch => fetch.get('api/Template_list')
    };
}

export function postBTemplate(obj) {
    return dispatch => dispatch({
        types: [POST_BT, POST_BT_S, POST_BT_F],
        promise: fetch => fetch.post('api/Template_list', {
            data: obj
        })
    }).then(() => dispatch(getBTemplates()));
}

export function updateBTemplate(obj) {
    return dispatch => dispatch({
        types: [UPDATE_BT, UPDATE_BT_S, UPDATE_BT_F],
        promise: fetch => fetch.put('api/Template_list', {
            data: obj
        })
    })
    .then((res) => {
        if (res.status === 1) {
          dispatch(setAlert(`Email template updated successfully`, "success"));
        } else if (res.status === 0) {
            dispatch(setAlert(`Error in updating email template`, "error"));
        
            return Promise.reject();
          }
    
        return res;
      })
    .then(() => dispatch(getBTemplates()));
}

export function deleteBTemplate(template_id) {
    return dispatch => dispatch({
        types: [DELETE_BT, DELETE_BT_S, DELETE_BT_F],
        promise: fetch => fetch.delete('api/Template_list', {
            params: {
                template_id
            }
        })
    })
    .then((res) => {
        if (res.status === 1) {
          dispatch(setAlert(`Email template deleted successfully`, "success"));
        } else if(res.status === 0) {
            dispatch(setAlert(`Error in deleting email template`, "error"));
    
            return Promise.reject();
        }
    
        return res;
      })
    .then(() => dispatch(getBTemplates()));
}

export function getTemplateVariables() {
    return {
        types: [GET_TV, GET_TV_S, GET_TV_F],
        promise: fetch => fetch.get('api/Template_variables')
    };
}

export function postTempVar(tempVarDetails) {
    return dispatch => dispatch({
        types: [POST_TEMPVAR, POST_TEMPVAR_S, POST_TEMPVAR_F],
        promise: fetch => fetch.post('api/Template_variables', {
            data: tempVarDetails
        })
    }).then((res) => {
        if (res.status === 1) {
          dispatch(setAlert(`New template variable saved`, "success"));
        } else if(res.status === 0) {
            dispatch(setAlert(res.message ? res.message : `Error in adding new variable`, "error"));
    
            return Promise.reject(res);
        }
    
        return res;
      });
}

export function updateTempVar(tempVarDetails) {
    return dispatch => dispatch({
        types: [UPDATE_TEMPVAR, UPDATE_TEMPVAR_S, UPDATE_TEMPVAR_F],
        promise: fetch => fetch.put('api/Template_variables', {
            data: tempVarDetails
        })
    }).then((res) => {
        if (res.status === 1) {
          dispatch(setAlert(`Template variable updated`, "success"));
        }
    
        return res;
      }).catch(() => {
        dispatch(setAlert(`Error in updating variable`, "error"));
    
        return Promise.reject();
      });
}

export function removeTempVar(var_id) {
    return dispatch => dispatch({
        types: [REM_TEMPVAR, REM_TEMPVAR_S, REM_TEMPVAR_F],
        promise: fetch => fetch.delete('api/Template_variables', {
            data: {
                var_id
            }
        })
    }).then((res) => {
        if (res.status === 1) {
          dispatch(setAlert(`Template variable removed`, "success"));
        } else if(res.status === 0) {
            dispatch(setAlert(res.message ? res.message : `Error in removing variable`, "error"));
    
            return Promise.reject(res);
        }
    
        return res;
      });
}

export function getScheduledContents(page = 0) {
    return {
        types: [GET_SCHED_CONTENTS, GET_SCHED_CONTENTS_S, GET_SCHED_CONTENTS_F],
        promise: fetch => fetch.get('api/Scheduled_contents', {
            params: {
                limit: 10,
                offset: page
            }
        })
    }
}

export function updateScheduledContent(schedule_id, schedule_time) {
    return dispatch => dispatch({
        types: [UPD_SCHED_CONTENT, UPD_SCHED_CONTENT_S, UPD_SCHED_CONTENT_F],
        promise: fetch => fetch.put('api/Scheduled_contents', {
            data: {
                schedule_id,
                schedule_time
            }
        })
    })
    .then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Schedule time updated`, "success"));
        }

        return res;
    })
    .catch(() => {
        dispatch(setAlert(`Error in updating time`, "error"));

        return Promise.reject();
    });
}

export function deleteScheduledContent(schedule_id) {
    return dispatch => dispatch({
        types: [DEL_SCHED_CONTENT, DEL_SCHED_CONTENT_S, DEL_SCHED_CONTENT_F],
        promise: fetch => fetch.delete('api/Scheduled_contents', {
            data: {
                schedule_id
            }
        })
    })
    .then((res) => {
        if (res.status === 1) {
            dispatch(setAlert(`Schedule email removed successfully.`, "success"));
        }

        return res;
    })
    .catch(() => {
        dispatch(setAlert(`Error in removing scheduled.`, "error"));

        return Promise.reject();
    });
}