export default {
    formsLinkUrl: process.env.REACT_APP_STAGING ? "https://form.staging.formsleads.com/n/" : "https://form.formsleads.com/n/",
    functions: {
        "view_forms": "1",
        "form_creation": "2",
        "form_edit": "3",
        "form_deletion": "4",
        "copy_snippet": "5",
        "view_leads": "6",
        "view_lead_details": "7",
        "view_full_email_id": "8",
        "view_lead_notes": "9", 
        "create_notes": "10",
        "change_lead_status": "11",
        "change_contact_type": "12",
        "change_contact_date_and_time": "13",
        "add_to_contact": "14",
        "archive": "15",
        "view_staffs": "16",
        "edit_staff": "17",
        "add_staff": "18",
        "priority_and_status_mapping_edit": "19",
        "edit_user_role_and_access": "20",
        "view_contacts": "21",
        "remove_contacts": "22",
        "edit_contact": "23",
        "add_edit_accounts": "24",
        "view_deals": "25",
        "add_edit_deal_stages": "26",
        "add_edit_deal_pipelines": "27",
        "activate_leads": "28",
        "lead_changes_notification": "29",
        "view_email_template_settings": "30",
        "lead_follow_up": "31",
        "contact_creation": "32",
        "contact_notes_view": "33",
        "contact_notes_add": "34",
        "contact_follow_up": "35",
        "create_deals": "36",
        "edit_deals": "37",
        "delete_deals": "38",
        "create_lead": "39",
        "db_backup": "40",
        "notification_logs": "41",
        "staff_assign": "42",
        "show_assigned_only": "43",
        "priority_sorting": "44",
        "show_app_key": "45",
        "generate_app_key": "46",
        "show_domains": "47",
        "add_domain": "48",
        "delete_domain": "49",
    },
    dNotes: {
        limit: 3
    },
    dTimeline: {
        limit: 3
    },
    leadNotes: {
        limit: 3
    },
    scheduled: {
        limit: 10
    },
    pusher: {
        app_key: "5486d322def00b5c38d9",
        cluster: "mt1"
    },
    userTypes: {
        "1": "admin",
        "2": "staff"
    },
    testLeadValues: [
        "1111111111",
        "111-111-1111",
        "(111) 111-1111",
        "test@incrediblevisibility.com"
    ],
    templateVariables: [
        "{leadname}"
    ],
    restrictedTV: [
        "{leadname}"
    ],
    commonFields: [
        "name",
        "email",
        "phone"
    ],
    dateFilters: [
        {
            code: 1,
            name: "Today",
            text: "Today"
        },
        {
            code: 2,
            name: "Yesterday",
            text: "Yesterday"
        },
        {
            code: 3,
            name: "Last 7 Days",
            text: "7Days"
        },
        {
            code: 4,
            name: "Last 30 Days",
            text: "30Days"
        }
    ],
    dateTimeFormats: [
        {
            timezone: "America/Los_Angeles",
            format: "MM-dd-yyyy, hh:mm a"
        },
        {
            timezone: "Asia/Kolkata",
            format: "dd-MM-yyyy, hh:mm a"
        },
        {
            timezone: "",
            format: "dd-MM-yyyy, hh:mm a"
        }
    ]
};